import React, { useRef, useState } from 'react';
import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute } from 'react-icons/fa'; // Import icons from react-icons

const Video2 = ({ videoSrc, thumbnailSrc }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const toggleMute = () => {
    videoRef.current.muted = !videoRef.current.muted;
    setIsMuted(videoRef.current.muted);
  };

  const handleProgress = () => {
    // Custom logic for progress updates can go here
  };

  const handleVolumeChange = (e) => {
    videoRef.current.volume = e.target.value;
  };

  return (
    <div className="relative w-full h-full">
      <video
        ref={videoRef}
        muted={isMuted}
        loop
        autoPlay
        className="w-full object-cover"
        onTimeUpdate={handleProgress}
        poster={thumbnailSrc}
      >
        <source src={videoSrc} type="video/mp4" />
      </video>
      
      {/* Controls */}
      <div className="absolute bottom-0 left-0 w-full flex justify-between p-4">
        <button
          onClick={togglePlayPause}
          className="text-white text-2xl bg-black bg-opacity-50 p-2 rounded-full"
          aria-label={isPlaying ? 'Pause' : 'Play'}
        >
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
        
        <button
          onClick={toggleMute}
          className="text-white text-2xl bg-black bg-opacity-50 p-2 rounded-full"
          aria-label={isMuted ? 'Unmute' : 'Mute'}
        >
          {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
        </button>
      </div>
      
      {/* Optional Volume Slider */}
      {/* 
      <div className="absolute bottom-0 left-0 w-full flex items-center p-4 bg-gradient-to-t from-black via-transparent to-transparent">
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          defaultValue="1"
          onChange={handleVolumeChange}
          className="w-full"
        />
      </div>
      */}
    </div>
  );
};

export default Video2;
