import React from 'react'
import FadeInSectionDownToUp from '../../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function NLP() {
    return (
        <div>
            <div>
                <img src='https://ik.imagekit.io/mhcockpit1/MHB/Projects/RC%20Plane.jpg' alt='Natural Language Processing' />
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <h1 className='text-center font-bold text-[#565EFF] text-4xl py-10'>Natural Language Processing</h1>
                </FadeInSectionDownToUp>
            </div>
            <div className='space-y-5 pb-10'>
                <FadeInSectionDownToUp>
                    <p className='px-40 xl:px-40 sm:px-36 max-sm:px-5 text-xl max-sm:text-lg text-center'>
                        Natural Language Processing (NLP) allows machines to understand, interpret, and respond to human language. In this course, students will dive into the world of NLP, learning how AI systems process language, analyze text, and engage in meaningful conversations. From chatbots to translation tools, NLP plays a crucial role in modern technology.
                    </p>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <p className='px-48 xl:px-48 sm:px-40 max-sm:px-5 text-xl max-sm:text-lg text-center'>
                        Students will explore core concepts such as text analysis, sentiment detection, and language generation using NLP tools and frameworks. By building their own NLP models, they will develop hands-on skills in data processing, machine learning, and linguistics, gaining an in-depth understanding of how AI can bridge the gap between human communication and machine intelligence.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <p className='text-center text-4xl space-x-2'>
                        <span className=''>Student's</span>
                        <span className='font-bold text-[#E355FF]'>Benefits</span>
                    </p>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <p className='text-center text-xl'>
                        from our projects
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex flex-wrap justify-center mt-10'>
                <div className='relative w-72 h-72 m-3'>
                    <div className='absolute inset-0 bg-cover bg-center' style={{ backgroundImage: "url('https://ik.imagekit.io/mhcockpit1/MHB/Projects/Sticky%20Notes.png')" }} />
                    <div className='relative p-5 flex items-center justify-center h-full'>
                        <FadeInSectionDownToUp>
                            <p className='text-white text-start px-5 -skew-y-6'>
                                <b>Beginner Program</b><br />
                                Learn how computers process human language by working on simple text analysis and chatbot projects to understand basic concepts of NLP.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
                <div className='relative w-72 h-72 m-3'>
                    <div className='absolute inset-0 bg-cover bg-center' style={{ backgroundImage: "url('https://ik.imagekit.io/mhcockpit1/MHB/Projects/Sticky%20Notes%20Pink.png')" }} />
                    <div className='relative p-5 flex items-center justify-center h-full'>
                        <FadeInSectionDownToUp>
                            <p className='text-white text-start px-5 -skew-y-6'>
                                <b>Intermediate Program</b><br />
                                Create applications like language translation, sentiment analysis, and speech recognition, applying AI techniques to work with real-world language tasks.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
                <div className='relative w-72 h-72 m-3'>
                    <div className='absolute inset-0 bg-cover bg-center' style={{ backgroundImage: "url('https://ik.imagekit.io/mhcockpit1/MHB/Projects/Sticky%20Notes.png')" }} />
                    <div className='relative p-5 flex items-center justify-center h-full'>
                        <FadeInSectionDownToUp>
                            <p className='text-white text-start px-5 -skew-y-6'>
                                <b>Advanced Program</b><br />
                                Develop complex NLP models using machine learning, working on projects such as real-time translation and AI-driven conversational systems.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <p className="text-4xl text-center pt-20">
                        <span className="text-black underline decoration-[#565EFF] px-1">Potential</span>
                        <b className="text-[#565EFF] font-bold px-1">Scope.</b>
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-10 xl:px-0 sm:px-36 max-sm:px-5'>
                <FadeInSectionDownToUp>
                    <p className='text-xl text-center font-bold'>
                        {/* The Natural Language Processing project */}
                        “<span className='text-[#565EFF]'>NLP</span> bridges the gap between human communication and computer understanding.”
                    </p>
                    {/* <p className='text-xl text-center'>
                        offers a wide range of potential uses in numerous industries in the future like,
                    </p> */}
                </FadeInSectionDownToUp>
            </div>
            <div className='py-10'>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='py-2 pl-48 xl:pl-48 sm:pl-36 max-sm:pl-5 text-xl xl:text-xl max-sm:text-lg font-bold text-[#565EFF]'>
                            Crafting Your Ideas
                        </p>
                        <p className='py-2 px-48 xl:px-48 sm:px-36 max-sm:px-5 text-xl xl:text-xl max-sm:text-lg text-justify'>
                            In this project, students explore the fundamentals of natural language processing through engaging activities. They experiment with text data, allowing their creativity to shine as they develop language-based applications.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='py-2 pl-48 xl:pl-48 sm:pl-36 max-sm:pl-5 text-xl xl:text-xl max-sm:text-lg font-bold text-[#E355FF]'>
                            Explore Your Interests
                        </p>
                        <p className='py-2 px-48 xl:px-48 sm:px-36 max-sm:px-5 text-xl xl:text-xl max-sm:text-lg text-justify'>
                            Students discover various applications of NLP, from chatbots to sentiment analysis tools. This exploration encourages them to consider the implications of language technology in everyday life.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='py-2 pl-48 xl:pl-48 sm:pl-36 max-sm:pl-5 text-xl xl:text-xl max-sm:text-lg font-bold text-[#565EFF]'>
                            Share Your Creations
                        </p>
                        <p className='py-2 px-48 xl:px-48 sm:px-36 max-sm:px-5 text-xl xl:text-xl max-sm:text-lg text-justify'>
                            By presenting their NLP projects, students can share insights and gather feedback from classmates. This collaborative approach inspires creativity and promotes further exploration of the field.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
        </div>
    )
}
