import React from 'react'

export default function Footer() {
    return (
        <div className='bg-black py-2 border-t border-white'>
            <div>
                <p className='text-center text-lg text-white'>
                    MHC Global Pvt. Ltd.
                </p>
            </div>
            <div class="flex justify-center items-center space-x-8 py-8">
                <div class="shadow-xl h-32 max-sm:h-20 flex justify-center items-center bg-white px-3 rounded-xl">
                    <img src="https://ik.imagekit.io/mhcockpit1/MHC%20Global/Startup%20India.jpg" alt="Accredited" />
                </div>
                <div class="shadow-xl h-32 max-sm:h-20 flex justify-center items-center bg-white px-3 rounded-xl">
                    <img src="https://ik.imagekit.io/mhcockpit1/MHC%20Global/MSME.jpg" alt="Accredited" />
                </div>
            </div>
            <div className='text-center text-lg text-white'>
                <p>Copyright © {new Date().getFullYear()} MH Intellect. All rights reserved.</p>
            </div>
        </div>
    )
}
