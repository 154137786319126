import React from "react";
import FadeInSectionDownToUp from "../../../Components/FadeAnimations/FadeInSectionDownToUp.js";

export default function AviAndAeroLab() {
  return (
    <section className="bg-black text-white flex flex-col overflow-hidden">
      <div className="container max-w-4xl px-5 sm:py-20 mx-auto h-screen flex flex-col items-start justify-center">
        <div className="">
          <FadeInSectionDownToUp>
            <h1 className="text-5xl sm:text-6xl font-bold from-pink-600 to-violet-600  bg-gradient-to-b bg-clip-text text-transparent">
              Why <br /> Aeronautics
              <br />& Aerospace Lab?
            </h1>
          </FadeInSectionDownToUp>

          <div className="max-w-2xl mt-10">
            <FadeInSectionDownToUp>
              <p className="text-white text-lg text-justify">
                Our lab features workstations, a reliable power supply, and
                essential safety and testing equipment, enabling young minds to
                assemble CanSats, CubeSats, and build drones, gliders, and RC
                planes. With meticulous maintenance and necessary resources, the
                lab ensures safety and allows for focused, distraction-free
                learning, fostering creativity and positive development.
              </p>
            </FadeInSectionDownToUp>
          </div>
        </div>
      </div>
      {/* <div className='flex justify-center text-white py-20'>
                <div className='grid grid-rows-2 grid-cols-2 gap-10 items-center'>
                    <div className=' flex justify-center items-center'>
                        <p>
                            <span className='text-xl'>Empowering Tomorrow's</span><br />
                            <span className='text-6xl text-yellow-300'>Aerospace</span><br />
                            <span className='text-3xl'>Pioneers!!</span>
                        </p>
                    </div>
                    <div className=''>
                        <img className='w-80 rounded-3xl' src='https://ik.imagekit.io/mhcockpit1/MHB/Aviation%20And%20Aerospace%20Lab/1.jpg' alt='AviAndAerospace Lab' />
                    </div>
                    <div className='relative h-full'>
                        <div className='bg-yellow-300 h-full rounded-3xl'>
                        </div>
                            <img className='absolute h-full object-cover overflow-auto -top-10 -left-10' src='https://ik.imagekit.io/mhcockpit1/MHB/Aviation%20And%20Aerospace%20Lab/3Png.png' alt='AviAndAerospace Lab' />
                    </div>
                    <div className=''>
                        <img className='w-80 rounded-3xl' src='https://ik.imagekit.io/mhcockpit1/MHB/Aviation%20And%20Aerospace%20Lab/2.jpg' alt='AviAndAerospace Lab' />
                    </div>
                </div>
            </div> */}
      <div className="container max-w-xl px-5 py-20 mx-auto ">
        <div className="grid grid-rows-2 grid-cols-2 gap-5 sm:gap-10 ">
          {/* <!-- Text Block --> */}
          {/* <div className="flex justify-center items-center rounded-3xl ml-5 w-48 sm:w-80">
            <p className="text-start leading-5 ">
              <span className="text-sm sm:text-xl">Empowering Tomorrow's</span>
              <br />
              <span className="text-2xl sm:text-5xl text-yellow-200">
                Aerospace
              </span>
              <br />
              <span className="text-lg Sm:text-3xl">Pioneers!!</span>
            </p>
          </div> */} 
          <img
              className="rounded-3xl"
              src="https://ik.imagekit.io/mhcockpit1/MHB/Lab/Aviation%20&%20Aerospace/Aero%2001.png"
              alt="AviAndAerospace Lab" 
              draggable="false"
            />
          {/* <!-- First Image --> */}
          <img
            className="rounded-3xl"
            src="https://ik.imagekit.io/mhcockpit1/MHB/Lab/Aviation%20&%20Aerospace/Aero%2002.jpg"
            alt="AviAndAerospace Lab"
            draggable="false"
          /> 
          <div className="relative bg-yellow-300 rounded-3xl">
            <img
              className="rounded-3xl absolute -left-10 sm:-left-14 bottom-0 max-w-48 sm:max-w-80"
              src="https://ik.imagekit.io/mhcockpit1/MHB/Lab/Aviation%20&%20Aerospace/3Png.png"
              alt="AviAndAerospace Lab" 
              draggable="false"   
            /> 
          </div>
          {/* <img
            className="rounded-3xl -mt-6 sm:-mt-14 "
            src="/3.png"
            alt="AviAndAerospace Lab"
            draggable="false"  
            style={{width:'375px'}}
          /> */}
          <img
            className="rounded-3xl"
            src="https://ik.imagekit.io/mhcockpit1/MHB/Lab/Aviation%20&%20Aerospace/Aero%2004.jpg"
            alt="AviAndAerospace Lab"
            draggable="false"
          />
        </div>
      </div>
      <div className="container max-w-4xl px-5 py-32 mx-auto">
        <div className="flex justify-end items-center max-w-4xl"> 
              <video width="700" autoPlay loop className="rounded-3xl">
                <source src="https://mh-intellect.blr1.cdn.digitaloceanspaces.com/STEM_Rocket.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
        <div className="flex justify-center items-center py-10">
          <FadeInSectionDownToUp>
            <p className="text-lg text-justify">
              Our Kids STEM Program introduces young learners to the exciting
              world of Aeronautics and Aerospace. Through engaging, Hands-on
              activities, Children explore the principles of flight, Rocket
              science, and Space exploration. Using age-appropriate technologies
              and interactive simulations, the program fosters creativity,
              Critical thinking, and Problem-solving skills. Kids build and test
              their own Aircraft and Rockets, gaining a foundational
              understanding of Aerospace engineering concepts. This fun and
              educational experience inspires a lifelong interest in Science and
              Technology, preparing the next generation of explorers. &
              innovators.
            </p>
          </FadeInSectionDownToUp>
        </div>
      </div>
      <div className="container max-w-4xl px-5 sm:py-20 mx-auto">
        <div className="pb-20">
          <div className="">
            <FadeInSectionDownToUp>
              <p className="text-5xl sm:text-7xl font-bold from-pink-600 to-violet-600  bg-gradient-to-b bg-clip-text text-transparent">
                Premier Aeronautics and Aerospace Education for Kids
              </p>
            </FadeInSectionDownToUp>
          </div>
          <div className="-mt-9 sm:-mt-12 text-lg sm:text-justify ml-20 sm:ml-32">
            <FadeInSectionDownToUp>
              <p className="text-white px-10 sm:px-12 ">
                We have established numerous Aeronautics and Aerospace Labs! Our
                commitment to advancing aviation technology is stronger than
                ever, and we look forward to continually pushing the boundaries
                of what is possible.
              </p>
            </FadeInSectionDownToUp>
          </div>
        </div>
      </div>
    </section>
  );
}
