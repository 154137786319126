import React from 'react'
import FadeInSectionDownToUp from '../../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function CADAeromodelling() {
  return (
    <div>
      <div>
        <img src='https://ik.imagekit.io/mhcockpit1/MHB/Projects/RC%20Plane.jpg' alt='CAD Aeromodelling' />
      </div>
      <div>
        <FadeInSectionDownToUp>
          <h1 className='text-center font-bold text-[#565EFF] text-4xl py-10'>CAD Aeromodelling</h1>
        </FadeInSectionDownToUp>
      </div>
      <div className='space-y-5 pb-10'>
        <FadeInSectionDownToUp>
          <p className='px-40 xl:px-40 sm:px-36 max-sm:px-5 text-xl max-sm:text-lg text-center'>
            The Computer-Aided Design (CAD) software to develop intricate models of aircraft, gaining valuable insights into aerodynamics and engineering principles. This project empowers them to transform creative ideas into precise 3D models, suitable for both virtual simulations and physical prototypes.
          </p>
        </FadeInSectionDownToUp>
        <FadeInSectionDownToUp>
          <p className='px-48 xl:px-48 sm:px-40 max-sm:px-5 text-xl max-sm:text-lg text-center'>
            Throughout the course, learners will master CAD tools, focusing on essential skills such as 3D modeling, technical drawing, and design iteration. They will design various aircraft types, including gliders and powered models, considering factors like weight distribution and material selection. By the end of the project, participants will have built a strong foundation in aeromodeling, creative design, and critical thinking, equipping them with the skills to bring their innovative concepts to life.
          </p>
        </FadeInSectionDownToUp>
      </div>
      <div>
        <FadeInSectionDownToUp>
          <p className='text-center text-4xl space-x-2'>
            <span className=''>Student's</span>
            <span className='font-bold text-[#E355FF]'>Benefits</span>
          </p>
        </FadeInSectionDownToUp>
        <FadeInSectionDownToUp>
          <p className='text-center text-xl'>
            from our projects
          </p>
        </FadeInSectionDownToUp>
      </div>
      <div className='flex flex-wrap justify-center mt-10'>
        <div className='relative w-72 h-72 m-3'>
          <div className='absolute inset-0 bg-cover bg-center' style={{ backgroundImage: "url('https://ik.imagekit.io/mhcockpit1/MHB/Projects/Sticky%20Notes.png')" }} />
          <div className='relative p-5 flex items-center justify-center h-full'>
            <FadeInSectionDownToUp>
              <p className='text-white text-start px-5 -skew-y-6'>
                <b>Beginner Program</b><br />
                Get introduced to the basics of aeromodelling using CAD software, learning how to design simple aircraft components like wings and fuselage with a focus on aerodynamic principles.
              </p>
            </FadeInSectionDownToUp>
          </div>
        </div>
        <div className='relative w-72 h-72 m-3'>
          <div className='absolute inset-0 bg-cover bg-center' style={{ backgroundImage: "url('https://ik.imagekit.io/mhcockpit1/MHB/Projects/Sticky%20Notes%20Pink.png')" }} />
          <div className='relative p-5 flex items-center justify-center h-full'>
            <FadeInSectionDownToUp>
              <p className='text-white text-start px-5 -skew-y-6'>
                <b>Intermediate Program</b><br />
                Create more detailed aircraft models by using advanced CAD tools, optimizing designs for weight distribution, stability, and aerodynamic efficiency.
              </p>
            </FadeInSectionDownToUp>
          </div>
        </div>
        <div className='relative w-72 h-72 m-3'>
          <div className='absolute inset-0 bg-cover bg-center' style={{ backgroundImage: "url('https://ik.imagekit.io/mhcockpit1/MHB/Projects/Sticky%20Notes.png')" }} />
          <div className='relative p-5 flex items-center justify-center h-full'>
            <FadeInSectionDownToUp>
              <p className='text-white text-start px-5 -skew-y-6'>
                <b>Advanced Program</b><br />
                Master CAD aeromodelling by designing fully functional aircraft, incorporating complex features like control surfaces, landing gear, and testing models for real-world aerodynamics.
              </p>
            </FadeInSectionDownToUp>
          </div>
        </div>
      </div>
      <div>
        <FadeInSectionDownToUp>
          <p className="text-4xl text-center pt-20">
            <span className="text-black underline decoration-[#565EFF] px-1">Potential</span>
            <b className="text-[#565EFF] font-bold px-1">Scope.</b>
          </p>
        </FadeInSectionDownToUp>
      </div>
      <div className='py-10 xl:px-0 sm:px-36 max-sm:px-5'>
        <FadeInSectionDownToUp>
          <p className='text-xl text-center font-bold'>
            {/* The CAD Aeromodelling project */}
            “<span className='text-[#565EFF]'>CAD Aeromodelling</span> brings designs to life, turning concepts into reality.”
          </p>
          {/* <p className='text-xl text-center'>
            offers a wide range of potential uses in numerous industries in the future like,
          </p> */}
        </FadeInSectionDownToUp>
      </div>
      <div className='py-10'>
        <div>
          <FadeInSectionDownToUp>
            <p className='py-2 pl-48 xl:pl-48 sm:pl-36 max-sm:pl-5 text-xl xl:text-xl max-sm:text-lg font-bold text-[#565EFF]'>
              Crafting Your Ideas
            </p>
            <p className='py-2 px-48 xl:px-48 sm:px-36 max-sm:px-5 text-xl xl:text-xl max-sm:text-lg text-justify'>
              In this project, students learn to use CAD software for aeromodelling. They create detailed designs for aircraft, allowing their creativity to shine as they visualize their concepts.
            </p>
          </FadeInSectionDownToUp>
        </div>
        <div>
          <FadeInSectionDownToUp>
            <p className='py-2 pl-48 xl:pl-48 sm:pl-36 max-sm:pl-5 text-xl xl:text-xl max-sm:text-lg font-bold text-[#E355FF]'>
              Explore Your Interests
            </p>
            <p className='py-2 px-48 xl:px-48 sm:px-36 max-sm:px-5 text-xl xl:text-xl max-sm:text-lg text-justify'>
              Students explore the principles of aerodynamics and engineering as they design their aircraft models. This exploration encourages them to apply their knowledge in practical, creative ways.
            </p>
          </FadeInSectionDownToUp>
        </div>
        <div>
          <FadeInSectionDownToUp>
            <p className='py-2 pl-48 xl:pl-48 sm:pl-36 max-sm:pl-5 text-xl xl:text-xl max-sm:text-lg font-bold text-[#565EFF]'>
              Share Your Creations
            </p>
            <p className='py-2 px-48 xl:px-48 sm:px-36 max-sm:px-5 text-xl xl:text-xl max-sm:text-lg text-justify'>
              By showcasing their CAD designs, students can share their ideas and gather feedback from peers. This collaborative atmosphere fosters innovation and inspires new approaches to aeromodelling.
            </p>
          </FadeInSectionDownToUp>
        </div>
      </div>
    </div>
  )
}
