// import React, { useState } from 'react';
// import ContactCover from '../../assets/Contact Us/Contact us.jpg';
// import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';
// import axios from 'axios';
// import { CountryCode } from '../../Components/Contact Us/CountryCode';

// export default function ContactUs() {
//     const [name, setName] = useState('');
//     const [mail, setMail] = useState('');
//     const [number, setNumber] = useState('');
//     const [countrycode, setCountrycode] = useState('');
//     const [designation, setDesignation] = useState('');
//     const [school, setSchool] = useState('');
//     const [state, setState] = useState('');
//     const [schedule, setSchedule] = useState('');
//     const [isButtonDisabled, setIsButtonDisabled] = useState(false);

//     const demoRequest = async () => {
//         const requiredFields = [name, mail, designation, countrycode, number, school, state, schedule];
//         const hasEmptyFields = requiredFields.some(field => field === '');
//         setIsButtonDisabled(true);

//         if (hasEmptyFields) {
//             alert('Please fill in all required fields.');
//             return;
//         }

//         try {
//             const newData = {
//                 Name: name,
//                 Email: mail,
//                 Designation: designation,
//                 Country_code: countrycode,
//                 Mobile_Number: number,
//                 School: school,
//                 State: state,
//                 Shedule: schedule
//             };
//             await axios.post('https://sheetdb.io/api/v1/cfdgft5tcyrje', newData);
//             setName('');
//             setMail('');
//             setNumber('');
//             setCountrycode('');
//             setDesignation('');
//             setSchool('');
//             setState('');
//             setSchedule('');
//         } catch (error) {
//             console.error('Error in Submission', error);
//         }
//     };

//     return (
//         <div className='relative h-full'>
//             <img src={ContactCover} alt='Contact' className='absolute inset-0 object-cover w-full h-screen' />
//             <div className='relative z-10 bg-black bg-opacity-50 h-screen'>
//                 <div className='text-center py-10'>
//                     <FadeInSectionDownToUp>
//                         <h1 className='text-8xl xl:text-8xl lg:text-6xl max-sm:text-6xl sm:text-6xl py-10 xl:py-10 max-sm:py-2 text-center leading-[80px] from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent'>
//                             Contact Us.
//                         </h1>
//                     </FadeInSectionDownToUp>
//                 </div>
//                 <div className='grid grid-cols-2 max-sm:block pb-20 relative z-10'>
//                     <div className='flex justify-center items-center'>
//                         <div>
//                             <div className='text-2xl text-white text-start font-semibold pb-20'>
//                                 <FadeInSectionDownToUp>
//                                     <p>MHB Applied Studies L.L.C</p>
//                                     <p>Dubai, UAE</p>
//                                     <p><b>Mail: </b>info@mhintellect.com</p>
//                                 </FadeInSectionDownToUp>
//                             </div>
//                             {/* <div className='text-2xl text-white text-start font-semibold pb-20'>
//                                 <FadeInSectionDownToUp>
//                                     <p>MHB Applied Studies L.L.C</p>
//                                     <p>Dubai, UAE</p>
//                                     <p><b>Mail: </b>info@mhintellect.com</p>
//                                 </FadeInSectionDownToUp>
//                             </div>
//                             <div className='text-2xl text-white text-start font-semibold pb-20'>
//                                 <FadeInSectionDownToUp>
//                                     <p>MHB Applied Studies L.L.C</p>
//                                     <p>Dubai, UAE</p>
//                                     <p><b>Mail: </b>info@mhintellect.com</p>
//                                 </FadeInSectionDownToUp>
//                             </div> */}
//                         </div>
//                     </div>
//                     <div className='flex justify-center items-start w-3/4 max-sm:w-full overflow-hidden max-sm:px-5'>
//                         <div>
//                             <FadeInSectionDownToUp>
//                                 <div className='grid grid-cols-2 gap-5 py-2'>
//                                     <div className='flex-row'>
//                                         <div><label className='text-white'>Full Name</label></div>
//                                         <div>
//                                             <input className='w-full py-1 pl-2 rounded-lg' type='text' placeholder='Enter Your Full Name' value={name} onChange={(e) => setName(e.target.value)} />
//                                         </div>
//                                     </div>
//                                     <div className='flex-row'>
//                                         <div><label className='text-white'>Email</label></div>
//                                         <div>
//                                             <input className='w-full py-1 pl-2 rounded-lg' type='email' placeholder='Email' value={mail} onChange={(e) => setMail(e.target.value)} />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </FadeInSectionDownToUp>
//                             <FadeInSectionDownToUp>
//                                 <div className='grid grid-cols-2 gap-5 py-2'>
//                                     <div className='flex-row'>
//                                         <div><label className='text-white'>Designation</label></div>
//                                         <div>
//                                             <select className='w-full py-1 pl-2 rounded-lg' value={designation} onChange={(e) => setDesignation(e.target.value)}>
//                                                 <option value="" disabled selected hidden>Designation</option>
//                                                 <option>Educator</option>
//                                                 <option>Management</option>
//                                                 <option>Principal</option>
//                                                 <option>Parent</option>
//                                                 <option>Trainer</option>
//                                                 <option>Student</option>
//                                                 <option>Others</option>
//                                             </select>
//                                         </div>
//                                     </div>
//                                     <div className='flex gap-5 py-2'>
//                                         <div className='flex-row'>
//                                             <div><label className='text-white'>Code</label></div>
//                                             <div>
//                                                 <select className='w-full py-1 pl-2 rounded-lg' value={countrycode} onChange={(e) => setCountrycode(e.target.value)}>
//                                                     {CountryCode.map(code => (
//                                                         <option
//                                                             key={code.value}
//                                                             value={code.value}
//                                                             disabled={code.disabled}
//                                                             selected={code.selected}
//                                                             hidden={code.hidden}
//                                                             className='text-start'
//                                                         >
//                                                             {code.value || code.text}
//                                                         </option>
//                                                     ))}
//                                                 </select>
//                                             </div>
//                                         </div>
//                                         <div className='flex-row'>
//                                             <div><label className='text-white'>Phone Number</label></div>
//                                             <div>
//                                                 <input className='w-full py-1 pl-2 rounded-lg' type='number' placeholder='Phone Number' value={number} onChange={(e) => setNumber(e.target.value)} />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </FadeInSectionDownToUp>
//                             <FadeInSectionDownToUp>
//                                 <div className='flex-row'>
//                                     <div><label className='text-white'>School / Institution</label></div>
//                                     <div>
//                                         <input className='w-full py-1 pl-2 rounded-lg' type='text' placeholder='School / Institution' value={school} onChange={(e) => setSchool(e.target.value)} />
//                                     </div>
//                                 </div>
//                             </FadeInSectionDownToUp>
//                             <FadeInSectionDownToUp>
//                                 <div className='grid grid-cols-2 gap-5 py-2 overflow-hidden'>
//                                     <div className='flex-row'>
//                                         <div><label className='text-white'>State</label></div>
//                                         <div>
//                                             <input className='w-full py-1 pl-2 rounded-lg' type='text' placeholder='Enter State' value={state} onChange={(e) => setState(e.target.value)} />
//                                         </div>
//                                     </div>
//                                     <div className='flex-row'>
//                                         <div><label className='text-white'>Schedule demo for</label></div>
//                                         <div>
//                                             <select className='w-full py-1 pl-2 rounded-lg' value={schedule} onChange={(e) => setSchedule(e.target.value)}>
//                                                 <option value="" disabled selected hidden>Select Options</option>
//                                                 <option>MH Intellect Aerospace Program</option>
//                                                 <option>MH Intellect STEM Program</option>
//                                                 <option>MH Intellect Workshop</option>
//                                                 <option>MH Lab for Aeronautics and Aerospace</option>
//                                                 <option>MH Lab for STEM & Robotics</option>
//                                                 <option>MH Lab for Artificial Intelligence and Machine Learning</option>
//                                             </select>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </FadeInSectionDownToUp>
//                             <center>
//                                 <div className='pt-5'>
//                                     <button className='bg-white text-black px-5 py-1 rounded-lg' type='submit' onClick={demoRequest}>Submit Request</button>
//                                 </div>
//                             </center>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }




import React, { useState } from 'react';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';
import axios from 'axios';
import { CountryCode } from '../../Components/Contact Us/CountryCode';

export default function ContactUs() {
    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    const [number, setNumber] = useState('');
    const [countrycode, setCountrycode] = useState('');
    const [designation, setDesignation] = useState('');
    const [school, setSchool] = useState('');
    const [state, setState] = useState('');
    const [schedule, setSchedule] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const demoRequest = async () => {
        const requiredFields = [name, mail, designation, countrycode, number, school, state, schedule];
        const hasEmptyFields = requiredFields.some(field => field === '');
        setIsButtonDisabled(true);

        if (hasEmptyFields) {
            alert('Please fill in all required fields.');
            return;
        }

        try {
            const newData = {
                Name: name,
                Email: mail,
                Designation: designation,
                Country_code: countrycode,
                Mobile_Number: number,
                School: school,
                State: state,
                Shedule: schedule
            };
            await axios.post('https://sheetdb.io/api/v1/cfdgft5tcyrje', newData);
            setName('');
            setMail('');
            setNumber('');
            setCountrycode('');
            setDesignation('');
            setSchool('');
            setState('');
            setSchedule('');
        } catch (error) {
            console.error('Error in Submission', error);
        }
    };

    return (
        <div className='relative h-full'>
            <img src="https://ik.imagekit.io/mhcockpit1/MHB/Contact%20Us/Contact%20us.jpg?updatedAt=1731647801790" alt='Contact' className='absolute inset-0 object-cover w-full h-full' />
            <div className='relative z-10 bg-black bg-opacity-50 h-full'>
                <div className='text-center py-10'>
                    <FadeInSectionDownToUp>
                        <h1 className='text-8xl xl:text-8xl lg:text-6xl max-sm:text-6xl sm:text-6xl py-10 xl:py-10 max-sm:py-2 text-center leading-[80px] from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent'>
                            Contact Us.
                        </h1>
                    </FadeInSectionDownToUp>
                </div>
                <div className='grid xl:grid grid-cols-2 xl:grid-cols-2 sm:block max-sm:block pb-20 relative z-10'>
                    <div className='flex justify-center items-center'>
                        <div>
                            <div className='text-2xl xl:text-2xl sm:text-4xl max-sm:text-xl text-white text-start font-semibold pb-20'>
                                <FadeInSectionDownToUp>
                                    <p>MHB Applied Studies L.L.C</p>
                                    <p>Dubai, UAE</p>
                                    <p><b>Mail: </b>info@mhintellect.com</p>
                                </FadeInSectionDownToUp>
                            </div>
                            <div className='text-2xl xl:text-2xl sm:text-4xl max-sm:text-xl text-white text-start font-semibold pb-20'>
                                <FadeInSectionDownToUp>
                                    <p>
                                        Featherlite building,<br />
                                        200 Feet Radial Rd,<br />
                                        Pallavaram,<br />
                                        Chennai - 600043.
                                    </p>
                                </FadeInSectionDownToUp>
                            </div>
                            <div className='text-2xl xl:text-2xl sm:text-4xl max-sm:text-xl text-white text-start font-semibold pb-20'>
                                <FadeInSectionDownToUp>
                                    <p>
                                        Al faisaliyah district 2276<br />
                                        Al khorayef commercial center<br />
                                        Al madinah road<br />
                                        Building no 8045<br />
                                        Jeddah - Saudi
                                    </p>
                                </FadeInSectionDownToUp>
                            </div>
                            {/* <div className='text-2xl xl:text-2xl sm:text-4xl max-sm:text-xl text-white text-start font-semibold pb-20'>
                                <FadeInSectionDownToUp>
                                    <p>
                                        Building No: 8045<br />
                                        Al Faisaliyah District 2276<br />
                                        Al Madinah Al Munawarah Road<br />
                                        Jeddah - Kingdom of Saudi Arabia
                                    </p>
                                </FadeInSectionDownToUp>
                            </div> */}
                        </div>
                    </div>
                    <div className='flex justify-center items-start w-3/4 xl:w-3/4 sm:w-full max-sm:w-full overflow-hidden xl:px-0 sm:px-40 max-sm:px-5'>
                        <div>
                            <FadeInSectionDownToUp>
                                <div className='grid grid-cols-2 gap-5 py-2'>
                                    <div className='flex-row'>
                                        <div><label className='text-white'>Full Name</label></div>
                                        <div>
                                            <input className='w-full py-1 pl-2 rounded-lg' type='text' placeholder='Enter Your Full Name' value={name} onChange={(e) => setName(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='flex-row'>
                                        <div><label className='text-white'>Email</label></div>
                                        <div>
                                            <input className='w-full py-1 pl-2 rounded-lg' type='email' placeholder='Email' value={mail} onChange={(e) => setMail(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='grid grid-cols-2 gap-5 py-2'>
                                    <div className='flex-row'>
                                        <div><label className='text-white'>Designation</label></div>
                                        <div>
                                            <select className='w-full py-1 pl-2 rounded-lg' value={designation} onChange={(e) => setDesignation(e.target.value)}>
                                                <option value="" disabled selected hidden>Designation</option>
                                                <option>Educator</option>
                                                <option>Management</option>
                                                <option>Principal</option>
                                                <option>Parent</option>
                                                <option>Trainer</option>
                                                <option>Student</option>
                                                <option>Others</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='flex gap-5 py-2'>
                                        <div className='flex-row'>
                                            <div><label className='text-white'>Code</label></div>
                                            <div>
                                                <select className='w-full py-1 pl-2 rounded-lg' value={countrycode} onChange={(e) => setCountrycode(e.target.value)}>
                                                    {CountryCode.map(code => (
                                                        <option
                                                            key={code.value}
                                                            value={code.value}
                                                            disabled={code.disabled}
                                                            selected={code.selected}
                                                            hidden={code.hidden}
                                                            className='text-start'
                                                        >
                                                            {code.value || code.text}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='flex-row w-full'>
                                            <div><label className='text-white'>Phone Number</label></div>
                                            <div>
                                                <input className='w-full py-1 pl-2 rounded-lg' type='number' placeholder='Phone Number' value={number} onChange={(e) => setNumber(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex-row'>
                                    <div><label className='text-white'>School / Institution</label></div>
                                    <div>
                                        <input className='w-full py-1 pl-2 rounded-lg' type='text' placeholder='School / Institution' value={school} onChange={(e) => setSchool(e.target.value)} />
                                    </div>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='grid grid-cols-2 gap-5 py-2 overflow-hidden'>
                                    <div className='flex-row'>
                                        <div><label className='text-white'>State</label></div>
                                        <div>
                                            <input className='w-full py-1 pl-2 rounded-lg' type='text' placeholder='Enter State' value={state} onChange={(e) => setState(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='flex-row'>
                                        <div><label className='text-white'>Schedule demo for</label></div>
                                        <div>
                                            <select className='w-full py-1 pl-2 rounded-lg' value={schedule} onChange={(e) => setSchedule(e.target.value)}>
                                                <option value="" disabled selected hidden>Select Options</option>
                                                <option>MH Intellect Aerospace Program</option>
                                                <option>MH Intellect STEM Program</option>
                                                <option>MH Intellect Workshop</option>
                                                <option>MH Lab for Aeronautics and Aerospace</option>
                                                <option>MH Lab for STEM & Robotics</option>
                                                <option>MH Lab for Artificial Intelligence and Machine Learning</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </FadeInSectionDownToUp>
                            <center>
                                <div className='pt-5'>
                                    <button className='bg-white text-black px-5 py-1 rounded-lg' type='submit' onClick={demoRequest}>Submit Request</button>
                                </div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
