import React, { useState } from 'react';

const LabCardAnim = () => {
    const [isCardsSplitTransition, setCardsSplitTransition] = useState(false);

    const cards = [
        { id: 1, src: "https://ik.imagekit.io/mhcockpit1/MHB/Lab/SR.jpg", title: "Card 1 Title", description: "Card description", href: '/stem-and-robotics' },
        { id: 2, src: "https://ik.imagekit.io/mhcockpit1/MHB/Lab/Aerospace.jpg", title: "Card 2 Title", description: "Card description", href: '/aviation-and-aero-lab' },
        { id: 3, src: "https://ik.imagekit.io/mhcockpit1/MHB/Lab/AIML.jpg", title: "Card 3 Title", description: "Card description", href: '/ai-and-ml' },
        { id: 4, src: "https://ik.imagekit.io/mhcockpit1/MHB/Lab/Card%20Img_1.jpg", title: "Card 4 Title", description: "Card description" },
    ];

    return (
        <div className=" py-5">

            <ul
                className="relative w-96 h-96 list-none m-auto p-5 cursor-pointer"
                onClick={() => setCardsSplitTransition(!isCardsSplitTransition)}
            >
                {cards.map((card, index) => (
                    <li
                        key={card.id}
                        className={`absolute overflow-hidden h-48 w-48 shadow-md transition-all duration-700 transform rounded-lg ${isCardsSplitTransition
                                ? index === 0
                                    ? "left-24 top-72"
                                    : index === 1
                                        ? "-left-56 top-72"
                                        : index === 2
                                            ? "left-96 top-72"
                                            : index === 3
                                                ? "left-24 top-0"
                                                : "left-48 top-144"
                                : "left-24 top-0"
                            }`}
                        style={{ transitionDelay: `${index * 0.2}s` }}
                    >
                        <a href={card.href}>
                            <img src={card.src} alt={card.title} className="w-full h-full object-cover rounded-lg" />
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LabCardAnim;
