import React from 'react'
import { MHCockpitBoardMembers, MHIntellectTeam, OurGovernanceData } from '../../Components/Our Governance/OurGovernanceData'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'
import { LiaLinkedin } from 'react-icons/lia'

export default function OurGovernance() {
    return (
        <div className='bg-white dark:bg-black pb-20'>
            <div>
                <FadeInSectionDownToUp>
                    <h1 className='text-8xl xl:text-6xl lg:text-6xl max-sm:text-4xl sm:text-6xl py-10 xl:py-10 max-sm:py-2 text-center leading-[80px] from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent'>
                        Our Governance.
                    </h1>
                </FadeInSectionDownToUp>
            </div>
            <div className='grid grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 max-sm:grid-cols-1 gap-5 max-sm:gap-2 px-28 xl:px-28 sm:px-10 max-sm:px-2'>
                {/* {OurGovernanceData.map((image, index) => (
                    <div key={index}>
                        <FadeInSectionDownToUp>
                            <div className='bg-white shadow-lg shadow-gray-300 dark:shadow-none rounded-3xl hover:scale-105 transition-transform duration-1000 ease-in-out'>
                                <img src={image.src} alt={image.alt} className='w-full h-full object-cover rounded-t-3xl' />
                                <div className='text-center py-3 font-semibold max-sm:max-md:font-normal px-5 max-sm:px-0'>
                                    <p>{image.name}</p>
                                    <p>{image.role}</p>
                                </div>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                ))} */}
                {OurGovernanceData.map((image, index) => (
                    <FadeInSectionDownToUp key={index}>
                        <div className='relative shadow-lg rounded-3xl hover:scale-105 transition-transform duration-700 ease-in-out'>
                            <img src={image.src} alt='Our Governance' className='rounded-t-3xl' />
                            {image.linkedin && ( // Check if the LinkedIn property exists
                                <a href={image.linkedin} target="_blank" rel="noopener noreferrer" className='absolute top-2 right-2'>
                                    <LiaLinkedin size={35} />
                                </a>
                            )}
                            <div className='py-2 text-center'>
                                <p>{image.name}</p>
                                <p>{image.role}</p>
                            </div>
                        </div>
                    </FadeInSectionDownToUp>
                ))}
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <h1 className='text-8xl xl:text-6xl lg:text-6xl max-sm:text-4xl sm:text-6xl py-10 xl:py-10 max-sm:py-2 text-center leading-[80px] from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent'>
                        MH Cockpit Board Members.
                    </h1>
                </FadeInSectionDownToUp>
            </div>
            <div className='grid grid-cols-3 max-sm:grid-cols-1 gap-5 max-sm:gap-2 px-28 xl:px-28 sm:px-10 max-sm:px-2'>
                {MHCockpitBoardMembers.map((image, index) => (
                    <div key={index}>
                        <FadeInSectionDownToUp>
                            <div className='bg-white shadow-lg shadow-gray-300 dark:shadow-none rounded-3xl hover:scale-105 transition-transform duration-1000 ease-in-out'>
                                <img src={image.src} alt={image.alt} className='w-full h-full object-cover rounded-t-3xl' />
                                <div className='text-center py-3 font-semibold max-sm:max-md:font-normal px-5 max-sm:px-0'>
                                    <p>{image.name}</p>
                                    <p>{image.role}</p>
                                </div>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                ))}
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <h1 className='text-8xl xl:text-6xl lg:text-6xl max-sm:text-6xl sm:text-6xl py-10 xl:py-10 max-sm:py-2 text-center leading-[80px] from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent'>
                        MH Intellect Team.
                    </h1>
                </FadeInSectionDownToUp>
            </div>
            <div className='grid grid-cols-3 max-sm:grid-cols-1 gap-5 max-sm:gap-2 px-28 xl:px-28 sm:px-10 max-sm:px-2'>
                {MHIntellectTeam.map((image, index) => (
                    <div key={index}>
                        <FadeInSectionDownToUp>
                            <div className='bg-white shadow-lg shadow-gray-300 dark:shadow-none rounded-3xl hover:scale-105 transition-transform duration-1000 ease-in-out'>
                                <img src={image.src} alt={image.alt} className='w-full h-full object-cover rounded-t-3xl' />
                                <div className='text-center py-3 font-semibold max-sm:max-md:font-normal px-5 max-sm:px-0'>
                                    <p>{image.name}</p>
                                    <p>{image.role}</p>
                                </div>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                ))}
            </div>
        </div>
    )
}
