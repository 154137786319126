import React, { useState } from 'react'
import axios from 'axios'
import { CountryCode } from '../../Components/Contact Us/CountryCode'

export default function ApplicationNew() {

    const [formData, setFormData] = useState({
        name: '',
        dob: '',
        grade: '',
        section: '',
        school: '',
        parentName: '',
        email: '',
        countryCode: '+971',
        contact: '',
        whatsappCountryCode: '+971',
        whatsappNumber: '',
        // group: '',
        batch: '',
        workshopDuration: '',
        transport: '',
        transportPlace: '',
        heardAbout: '', // new field for how they heard about us
        otherSource: '', // new field for "Other" option
    })

    const [errors, setErrors] = useState({})

    const validate = () => {
        const newErrors = {}
        if (!formData.name) newErrors.name = 'Name is required'
        if (!formData.dob) newErrors.dob = 'Date of birth is required'
        if (!formData.grade) newErrors.grade = 'Grade/Year is required'
        if (!formData.school) newErrors.school = 'School Name is required'
        if (!formData.parentName) newErrors.parentName = 'Parent Name is required'
        if (!formData.email) newErrors.email = 'Email is required'
        if (!formData.contact) newErrors.contact = 'Contact number is required'
        if (!formData.whatsappNumber) newErrors.whatsappNumber = 'Whatsapp number is required'
        // if (!formData.group) newErrors.group = 'Select Group'
        if (!formData.batch) newErrors.batch = 'Select Batch'
        if (!formData.workshopDuration) newErrors.workshopDuration = 'Select Workshop Duration'
        if (!formData.transport) newErrors.transport = 'Select Transport'
        if (!formData.heardAbout) newErrors.heardAbout = 'Please select how you heard about us'

        if (formData.transport === 'Yes' && !formData.transportPlace) {
            newErrors.transportPlace = 'Transport place is required when transport is selected'
        }
        if (formData.heardAbout === 'Other' && !formData.otherSource) {
            newErrors.otherSource = 'Please specify how you heard about us'
        }


        if (Object.keys(newErrors).length > 0) {
            alert('Please fill in all required fields.')
        }

        return newErrors
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const validationErrors = validate()
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors)
            return
        }

        try {
            // const serialNumber = `SN-${Date.now()}`;
            // let serialNumber = parseInt(localStorage.getItem('serialNumber')) || 1000; // Starting number is 1000

            // Increment the serial number for the next submission
            // localStorage.setItem('serialNumber', serialNumber + 1);

            const currentDateTime = new Date();
            const submissionDate = currentDateTime.toLocaleDateString();
            const submissionTime = currentDateTime.toLocaleTimeString();

            const postData = {
                // Serial_Number: serialNumber,
                // Serial_Number: `MHI-${serialNumber}`,
                Submission_Date: submissionDate,
                Submission_Time: submissionTime,
                Name: formData.name,
                DOB: formData.dob,
                Grade: formData.grade,
                Section: formData.section,
                School: formData.school,
                Parent_Name: formData.parentName,
                Email: formData.email,
                Country_Code: formData.countryCode,
                Contact: formData.contact,
                Whatsapp_Country_Code: formData.whatsappCountryCode,
                Whatsapp_Number: formData.whatsappNumber,
                // Group: formData.group,
                Batch: formData.batch,
                Workshop_Duration: formData.workshopDuration,
                Transport: formData.transport,
                Transport_Place: formData.transport === 'Yes' ? formData.transportPlace : '',
                Heard_About: formData.heardAbout,
                Other_Source: formData.heardAbout === 'Other' ? formData.otherSource : '',
            }

            const response = await axios.post('https://sheetdb.io/api/v1/u8igtcvz0qzcq', postData)

            console.log('Form submitted successfully', response)
            // alert('Your application has been submitted successfully!, Our Team Will Be Contact You Soon!')
            alert('Thank you for your application! We’ve received your submission successfully. Our team will review it and get in touch with you shortly. If you have any questions in the meantime, feel free to reach out to us!')
            setFormData({
                name: '',
                dob: '',
                grade: '',
                section: '',
                school: '',
                parentName: '',
                email: '',
                countryCode: '',
                contact: '',
                whatsappCountryCode: '',
                whatsappNumber: '',
                // group: '',
                batch: '',
                workshopDuration: '',
                transport: '',
                transportPlace: '',
                heardAbout: '',
                otherSource: '',
            })
            window.location.href = '/winter-workshop'
        } catch (error) {
            console.error('Error submitting form:', error)
            alert('There was an error submitting your application. Please try again later.')
        }
    }

    return (
        <div className='bg-gradient-to-b from-[#b876ff] via-[#01c8ff99] to-[#3401ff99] py-10 max-sm:py-5'>
            <div className='mx-40 xl:mx-40 sm:mx-20 max-sm:mx-5 px-10 max-sm:px-5 py-10 bg-white bg-opacity-50 rounded-3xl max-sm:rounded-2xl'>
                <div>
                    <p className='text-center text-4xl font-semibold pb-2 border-b border-black'>Enroll Now</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='flex justify-center items-center py-5'>
                        <div className='grid grid-cols-2 max-sm:grid-cols-1 gap-10 max-sm:gap-2 w-full'>
                            <div>
                                <label className='block'>Name:<span className='text-red-600'>*</span></label>
                                <input
                                    className={`bg-transparent border ${errors.name ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='text'
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder='Your Full Name'
                                />
                                {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                            </div>
                            <div>
                                <label className='block'>Date of Birth:<span className='text-red-600'>*</span></label>
                                <input
                                    className={`bg-transparent border ${errors.dob ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='date'
                                    name='dob'
                                    value={formData.dob}
                                    onChange={handleChange}
                                />
                                {errors.dob && <p className="text-red-500 text-sm">{errors.dob}</p>}
                            </div>
                            <div>
                                <label className='block'>Grade:<span className='text-red-600'>*</span></label>
                                <select
                                    className={`bg-transparent border ${errors.grade ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    name='grade'
                                    value={formData.grade}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected hidden>Select Yours</option>
                                    <option>KG</option>
                                    <option>1st</option>
                                    <option>2nd</option>
                                    <option>3rd</option>
                                    <option>4th</option>
                                    <option>5th</option>
                                    <option>6th</option>
                                    <option>7th</option>
                                    <option>8th</option>
                                    <option>9th</option>
                                    <option>10th</option>
                                    <option>11th</option>
                                    <option>12th</option>
                                </select>
                                {errors.grade && <p className="text-red-500 text-sm">{errors.grade}</p>}
                            </div>
                            <div>
                                <label className='block'>Section:</label>
                                <input
                                    className={`bg-transparent border border-black rounded w-full py-2 px-3`}
                                    type='text'
                                    placeholder='Section'
                                    name='section'
                                    value={formData.section}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <label className='block'>School Name:<span className='text-red-600'>*</span></label>
                                <input
                                    className={`bg-transparent border ${errors.school ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='text'
                                    placeholder='School Name'
                                    name='school'
                                    value={formData.school}
                                    onChange={handleChange}
                                />
                                {errors.school && <p className='text-red-500 text-sm'>{errors.school}</p>}
                            </div>
                            <div>
                                <label className='block'>Parent Name:<span className='text-red-600'>*</span></label>
                                <input
                                    className={`bg-transparent border ${errors.parentName ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='text'
                                    placeholder='Parent Name'
                                    name='parentName'
                                    value={formData.parentName}
                                    onChange={handleChange}
                                />
                                {errors.parentName && <p className='text-red-500 text-sm'>{errors.parentName}</p>}
                            </div>
                            <div>
                                <label className='block'>Parent Email:<span className='text-red-600'>*</span></label>
                                <input
                                    className={`bg-transparent border ${errors.email ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='email'
                                    placeholder='Email'
                                    name='email'
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                {errors.email && <p className='text-red-500 text-sm'>{errors.email}</p>}
                            </div>
                            <div>
                                <label className='block'>Parent Contact Number:<span className='text-red-600'>*</span></label>
                                <div className='flex justify-center items-center gap-2'>
                                    <select
                                        className={`bg-transparent border text-center ${errors.contact ? 'border-red-500' : 'border-black'} rounded w-1/4 py-2`}
                                        name='countryCode'
                                        value={formData.countryCode}
                                        onChange={handleChange}
                                    >
                                        {CountryCode.map(code => (
                                            <option
                                                key={code.value}
                                                value={code.value}
                                                // disabled={code.disabled}
                                                selected={code.selected}
                                                hidden={code.hidden}
                                                className='text-start'
                                            >
                                                {code.value || code.text}
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        className={`bg-transparent border ${errors.contact ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                        type='number'
                                        placeholder='eg:5xxxxxxxx'
                                        name='contact'
                                        value={formData.contact}
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.contact && <p className='text-red-500 text-sm'>{errors.contact}</p>}
                            </div>
                            <div>
                                <label className='block'>Whatsapp Number:<span className='text-red-600'>*</span></label>
                                <div className='flex justify-center items-center gap-2'>
                                    <select
                                        className={`bg-transparent border text-center ${errors.whatsappNumber ? 'border-red-500' : 'border-black'} rounded w-1/4 py-2`}
                                        name='whatsappCountryCode'
                                        value={formData.whatsappCountryCode}
                                        onChange={handleChange}
                                    >
                                        {CountryCode.map(code => (
                                            <option
                                                key={code.value}
                                                value={code.value}
                                                disabled={code.disabled}
                                                selected={code.selected}
                                                hidden={code.hidden}
                                                className='text-start'
                                            >
                                                {code.value || code.text}
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        className={`bg-transparent border ${errors.whatsappNumber ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                        type='number'
                                        placeholder='eg:5xxxxxxxx'
                                        name='whatsappNumber'
                                        value={formData.whatsappNumber}
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.whatsappNumber && <p className='text-red-500 text-sm'>{errors.whatsappNumber}</p>}
                            </div>
                            {/* <div>
                                <label className='block'>Group:<span className='text-red-600'>*</span></label>
                                <select
                                    className={`bg-transparent border ${errors.group ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    name='group'
                                    value={formData.group}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected hidden>Select Group</option>
                                    <option>3rd - 5th Std</option>
                                    <option>6th - 8th Std</option>
                                    <option>9th - 12th Std</option>
                                </select>
                                {errors.group && <p className="text-red-500 text-sm">{errors.group}</p>}
                            </div> */}
                            <div>
                                <label className='block'>Batch:<span className='text-red-600'>*</span></label>
                                <select
                                    className={`bg-transparent border ${errors.batch ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    name='batch'
                                    value={formData.batch}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected hidden>Select Batch</option>
                                    <option>Morning Batch - 9:30AM - 12:30PM</option>
                                    <option>Afternoon Batch - 1:30PM - 4:30PM</option>
                                </select>
                                {errors.batch && <p className="text-red-500 text-sm">{errors.batch}</p>}
                            </div>
                            <div>
                                <label className='block'>Workshop Duration:<span className='text-red-600'>*</span></label>
                                <select
                                    className={`bg-transparent border ${errors.workshopDuration ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    name='workshopDuration'
                                    value={formData.workshopDuration}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected hidden>Select Type</option>
                                    <option>1 Week</option>
                                    <option>2 Weeks</option>
                                </select>
                                {errors.workshopDuration && <p className="text-red-500 text-sm">{errors.workshopDuration}</p>}
                            </div>
                            {/* <div>
                                <label className='block'>Transport:<span className='text-red-600'>*</span></label>
                                <select
                                    className={`bg-transparent border ${errors.transport ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    name='transport'
                                    value={formData.transport}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected hidden>Select Transport</option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                                {errors.transport && <p className="text-red-500 text-sm">{errors.transport}</p>}
                            </div>
                            <div>
                                <label className='block'>If Yes, Place:</label>
                                <input
                                    className={`bg-transparent border border-black rounded w-full py-2 px-3`}
                                    type='text'
                                    name="transportPlace"
                                    value={formData.transportPlace}
                                    onChange={handleChange}
                                    placeholder='Place'
                                />
                            </div> */}
                            <div>
                                <label className='block'>Transport:<span className='text-red-600'>*</span></label>
                                <select
                                    className={`bg-transparent border ${errors.transport ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    name='transport'
                                    value={formData.transport}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected hidden>Select Transport</option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                                {errors.transport && <p className="text-red-500 text-sm">{errors.transport}</p>}
                            </div>

                            {/* Show transport place input only if "Yes" is selected for transport */}
                            {formData.transport === 'Yes' && (
                                <div>
                                    <label className='block'>If Yes, Place:<span className='text-red-600'>*</span></label>
                                    <input
                                        className={`bg-transparent border ${errors.transportPlace ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                        type='text'
                                        name="transportPlace"
                                        value={formData.transportPlace}
                                        onChange={handleChange}
                                        placeholder='Enter transport place'
                                    />
                                    {errors.transportPlace && <p className="text-red-500 text-sm">{errors.transportPlace}</p>}
                                </div>
                            )}
                            <div>
                                <label className='block'>How did you hear about us?<span className='text-red-600'>*</span></label>
                                <select
                                    className={`bg-transparent border ${errors.heardAbout ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    name='heardAbout'
                                    value={formData.heardAbout}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected hidden>Select Option</option>
                                    <option>Instagram</option>
                                    <option>Facebook</option>
                                    <option>Google Search</option>
                                    <option>Other</option>
                                </select>
                                {errors.heardAbout && <p className="text-red-500 text-sm">{errors.heardAbout}</p>}
                            </div>
                            {formData.heardAbout === 'Other' && (
                                <div>
                                    <label className='block'>Please specify:<span className='text-red-600'>*</span></label>
                                    <input
                                        className={`bg-transparent border ${errors.otherSource ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                        type='text'
                                        name='otherSource'
                                        value={formData.otherSource}
                                        onChange={handleChange}
                                        placeholder='Specify how you heard about us'
                                    />
                                    {errors.otherSource && <p className="text-red-500 text-sm">{errors.otherSource}</p>}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className=' flex justify-center items-center py-10'>
                        <button
                            type="submit"
                            className='bg-white hover:bg-slate-300 hover:scale-105 transition-all ease-linear duration-300 text-black text-xl py-2 px-5 rounded'
                        >
                            Submit
                        </button>
                    </div>
                </form>
                <div className='text-xl max-sm:text-lg'>
                    <p><b>NOTE:</b></p>
                    <ul className='list-disc pl-5'>
                        <li>The Winter Program will be conducted at ASPAM IIS, Sharjah.</li>
                        <li>School uniform is not mandatory.</li>
                        <li>Participants are required to bring basic stationery, including a notebook and pen.</li>
                        <li>Refreshments will be provided during the session.</li>
                        <li>Each session will have a duration of 3 hours.</li>
                    </ul>
                </div>
                <div className='text-xl max-sm:text-lg pt-10'>
                    <p className='text-2xl pb-5'><b>Contact Us:</b></p>
                    <p><b>Email: </b><a href='mailto:info@mhintellect.com'>info@mhintellect.com</a></p>
                    <p><b>Contact: </b><a href='tel:+971552245123'>+971 552 245 123</a></p>
                </div>
            </div>
        </div>
    )
}