const products = [

  {
    id: 27,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Robo%20Dog1.jpg",
    title: "Go2 Robot Dog",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "The Dog Robot project introduces students to robotics and programming through the use of an interactive, lifelike robotic dog. By building and programming this four-legged robot, students explore the mechanics of movement, coding for autonomous behaviors, and the principles of robotics. It’s an engaging way to connect technology with creativity and hands-on engineering.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Students learn about robotic mechanics and movement by exploring how joints, motors, and robotic structures work together to create lifelike motions. They develop coding and programming skills to control the robot’s behavior, enhancing their computational thinking and programming knowledge. Through sensor-based robotics, students gain hands-on experience using sensors to detect and respond to environmental stimuli, making the robot interactive and autonomous. By designing and programming unique behaviors and interactions, they foster creativity and innovation in robotic design. This experience also helps students understand how robotics principles apply to real-world technologies such as autonomous vehicles, robotic pets, and service robots.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "The Dog Robot project combines the excitement of building and programming with the joy of creating a lifelike companion. It’s not just about robotics it’s about discovering the possibilities of interactive technology and lifelike engineering!",
  },
  {
    id: 1,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Foam%20Board%20RC.jpg",
    title: "Foam Board RC Plane",
    description: "Beginner Level",
    buttonText: "Start Your Journey",
    details: "The Foam Board RC Plane Kit allows students to experience the fundamentals of flight by building their own remote-controlled airplane. This kit provides all the necessary materials to assemble a lightweight foam board plane, teaching students the principles of aerodynamics, electronics, and remote control systems. It’s an excellent way to explore the basics of aviation while developing hands-on skills in building and piloting.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Through the Foam Board RC Plane project, students will learn how airplanes fly and the mechanics behind flight control. They will enhance their understanding of aerodynamics, motor function, and electronics, while building valuable skills in problem-solving and creative thinking. By the end of the project, students will have a functional RC plane and a deeper appreciation for aviation and engineering concepts.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure top performance and durability.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Expert advice and assistance are always available to help you succeed.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Community",
        description: "Join our passionate RC enthusiasts and share your experiences.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "Building your own plane has never been easier! The Foam Board RC Plane is perfect for students who want to learn about aerodynamics while having fun. It’s simple to construct and thrilling to fly, making it a great project for anyone who loves aviation.",
  },
  {
    id: 13,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/DIY%20Writing%20Machine.jpg",
    title: "DIY Drawing Machine",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "The DIY Writing & Art Machine is a super cool way for kids to combine drawing and writing! With this fun machine, you can easily create awesome designs and write down your thoughts, all by turning a couple of knobs. It’s easy to build, made from safe materials, and gives you the chance to explore your creativity while learning something new.",
    outcomeTitle: "Outcome",
    outcomeDescription: "With the DIY Writing & Art Machine, you’ll get to improve your drawing skills and practice writing in a whole new way. As you make cool patterns and write your own messages, you’ll also learn how to solve problems, understand basic math concepts like shapes and lines, and boost your hand-eye coordination. It’s the perfect mix of fun and learning!",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "Let your creativity shine with the DIY Drawing Machine! This project teaches you how to build a machine that draws cool patterns and designs. It’s a fun mix of art and engineering, and you can even program it to create your own unique artwork!",
  },
  {
    id: 16,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Self-Balancing%20Car%20Kit.jpg",
    title: "Self-Balancing Car Kit",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "The Self-Balancing Car Kit is an exciting and educational project for kids to explore robotics and engineering. This kit allows you to build a car that can balance itself on two wheels, using sensors and motors to stay upright. It’s a great way to dive into concepts like motion control, stability, and automation while having fun building your own robot car.",
    outcomeTitle: "Outcome",
    outcomeDescription: "By building the Self-Balancing Car, you’ll learn how sensors and motors work together to maintain balance and control motion. You’ll explore concepts like gyroscopic stability, feedback loops, and programming, all while developing problem-solving skills and a deeper understanding of robotics. This project enhances creativity and introduces the basics of automation and mechanical design in an engaging, hands-on way.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "Build and program your own car that balances on two wheels! Learn how sensors and coding work together to create real-world tech. Perfect for young engineers ready to explore robotics and control systems!",
  },
  {
    id: 14,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/DIY%20Smart%20Farm%20Kit.jpg",
    title: "DIY Smart Farm Kit",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "The DIY Smart Farm Kit lets kids explore the world of farming and technology by building their own smart farm! This hands-on kit teaches you how to grow plants using sensors and automation, just like real smart farms do. It’s fun and easy to set up, with safe materials, and helps you learn how technology can make farming more efficient.",
    outcomeTitle: "Outcome",
    outcomeDescription: "With the DIY Smart Farm Kit, you’ll learn how to use sensors to monitor things like soil moisture and light levels to help your plants grow. You’ll also discover the basics of programming and automation, gaining problem-solving skills and understanding how smart technology can improve farming. It’s a great way to mix science, technology, and nature while having fun growing your own plants!",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "Ever wondered how technology can help plants grow? With the DIY Smart Farm Kit, you’ll get to create your own mini farm that uses sensors and smart technology to take care of plants. Learn how to monitor soil moisture, control watering systems, and even track sunlight—just like real farmers do! It’s a hands-on project that combines coding, robotics, and nature, giving you the chance to grow something amazing while learning how smart farms work. Perfect for budding scientists and future environmental engineers!",
  },
  {
    id: 23,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Kubo%20Robot.jpg",
    title: "KUBO Robot",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "Kubo Coding Robot is an engaging and intuitive robot designed to introduce young students to the basics of coding and computational thinking. Kubo uses a unique, hands-on approach with its TagTile system, allowing students to learn coding concepts through tangible and interactive play, making it ideal for early learners in classrooms or at home.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Students are introduced to core coding concepts such as sequencing, loops, and conditionals through hands-on play, building a strong foundation in coding fundamentals. Using the TagTile® system, they develop logical thinking and problem-solving skills as they design paths and tackle challenges. Group activities encourage collaboration, fostering teamwork and communication as they work together on problem-solving tasks. By integrating coding with storytelling and narrative-based challenges, students exercise creativity and connect coding to storytelling. This experience links coding with broader STEM skills, revealing connections to robotics and other STEM fields, laying a foundation for further exploration.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "Kubo Coding Robot isn’t just a tool for learning it’s a gateway to creativity, problem-solving, and early STEM exploration. With Kubo, students aren’t just learning to code; they’re discovering new ways to think and create!",
  },
  {
    id: 5,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Nano%20Drone.jpg",
    title: "Nano Drone",
    description: "Beginner Level",
    buttonText: "Start Your Aerial Adventure",
    details: "The Nano Drone Kit offers an exciting entry point for school students interested in drone technology. Designed for beginners, this kit provides all the components required to build and pilot a small, easy-to-use drone. As students assemble and operate the drone, they’ll explore fundamental concepts such as aerodynamics, stability, and basic electronics in a straightforward and enjoyable manner, perfect for sparking interest in tech and engineering.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Working with the Nano Drone Kit allows students to get hands-on experience with drone assembly and flight control. They will develop a practical understanding of how drones maneuver and the role of electronics in controlling movement. This project encourages critical thinking and provides an accessible introduction to technology, giving students a solid foundation to explore more advanced topics in robotics and flight.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure top performance and durability.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Expert advice and assistance are always available to help you succeed.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Community",
        description: "Join our passionate drone enthusiasts and share your experiences.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "Tiny but mighty, the Nano Drone is perfect for students who want to learn how to pilot drones indoors or outdoors. Its compact size makes it great for practicing tricks and maneuvers, and it's super fun to fly with friends!",
  },
  {
    id: 24,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Make%20Block%20mBot%202.jpg",
    title: "Makeblock mBot2",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "The mBot2 is an advanced educational robot designed to ignite students' passion for robotics, coding, and engineering. Perfect for classrooms and extracurricular activities, mBot2 opens up an interactive world for learning foundational STEM concepts while building real-world skills in a hands-on way.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Through hands-on building, students gain practical skills in robotics assembly and engineering, deepening their understanding of robotics principles. By working with both visual and text-based programming, they develop foundational coding skills that nurture computational thinking. Additionally, they explore real-world applications of AI and IoT through engaging projects, such as environmental monitoring and voice interaction. Collaborative challenges foster teamwork and emphasize critical thinking, enhancing group problem-solving skills. The mBot2 provides a bridge to real-world industries, demonstrating how coding and robotics are utilized in fields like healthcare, manufacturing, and space exploration.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "mBot2 aims to inspire and prepare students for future technological trends through creativity and hands-on discovery. It’s not just about learning robotics—it’s about powering the future of innovation!",
  },
  {
    id: 10,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Humanoid.jpg",
    title: "Humanoid Robot",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "The Humanoid Robot Kit allows students to explore robotics by building and programming a robot that mimics human movements. This kit provides everything needed to assemble a walking, talking, and interacting robot. As students work through the project, they’ll learn about motor control, sensors, and coding while experiencing how robots can be designed to perform tasks similar to humans.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Upon completing the Humanoid Robot project, students will have hands-on experience with robotics, learning how to program movements and integrate sensors for interaction. This project develops skills in coding, problem-solving, and mechanical design, offering a practical understanding of how robots can be used in real-world applications like assistance and automation.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "Imagine building a robot that walks, talks, and interacts with you! The Humanoid Robot project lets students explore robotics, coding, and artificial intelligence as they build a robot that can move and even recognize faces. It’s a fun way to learn about the future of tech!",
  },
  {
    id: 11,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Intellect%20Advanced%20STEM%20Kit%20Box.jpg",
    title: "Advanced STEM Kit",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "The Advanced Robotics STEM Kit is a comprehensive learning tool designed to introduce school students to the exciting world of robotics. This kit provides everything needed to build, program, and experiment with robots through hands-on projects like light-following robots, pick-and-place machines, and smartphone-controlled devices. Students will engage with essential STEM fields, including electronics, mechanical design, and coding, while developing critical thinking and problem-solving skills. Suitable for both classroom settings and home learning, the kit is designed to foster creativity and innovation.",
    outcomeTitle: "Outcome",
    outcomeDescription: "By using the Advanced Robotics STEM Kit, students will gain a deeper understanding of robotics and its applications. They will develop key skills in programming, mechanics, and electronics, while building confidence in their ability to solve complex problems. The kit encourages students to think critically, work collaboratively, and apply theoretical knowledge to real-world challenges. Ultimately, students will walk away with a solid foundation in robotics and a passion for STEM learning.",
    features: [
      {
        title: "Quality",
        description: "All materials are designed with safety in mind, making it a perfect kit for young learners.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "Imagine building your own robots, circuits, and gadgets—all in one kit! The Advanced STEM Kit is packed with cool, hands-on projects that help you explore science, technology, engineering, and math in a fun and interactive way. Perfect for school projects or just because you're curious!",
  },
  {
    id: 9,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Can%20Sat.jpg",
    title: "CanSat",
    description: "Educational Satellite Model",
    buttonText: "Launch Your Learning Journey",
    details: "The CanSat Kit provides students with a hands-on way to explore satellite technology and space systems. With this kit, students can build and launch their own mini satellite, learning how sensors work, how to collect data, and how telemetry functions in real-world applications. It's an exciting opportunity to learn about aerospace engineering, communication systems, and environmental monitoring in a practical and engaging manner.",
    outcomeTitle: "Outcome",
    outcomeDescription: "By completing the CanSat project, students will gain a strong understanding of how satellites operate and their use in everyday life. They will develop skills in coding, data analysis, and design while learning how to measure and interpret environmental data. This experience promotes problem-solving, teamwork, and technical thinking, setting the stage for future studies in engineering and technology.",
    features: [
      {
        title: "Quality",
        description: "We use advanced materials and technologies to ensure the reliability and performance of our CubeSats and CanSats.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Stay at the forefront of space technology with our cutting-edge satellite platforms and educational tools.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "Have you ever wondered what it’s like to launch your own satellite? With the CanSat kit, you can design and launch a mini satellite just like the real thing. Learn how space technology works and send your creation soaring into the sky!",
  },
  {
    id: 26,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/pi-Top%20Kit.jpg",
    title: "Pi-top Robotics Kit",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "The Pi-top Kit introduces students to the world of computing, electronics, and creative technology. By using the Pi-top, a modular laptop based on the Raspberry Pi, students can build their own computer, learn coding, and explore hardware projects. It’s an all-in-one platform for hands-on STEM learning, blending creativity with technical skills.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Students gain practical skills in hardware assembly by building and customizing a modular laptop, learning how computers function from the inside out. They explore various programming languages, developing coding skills and creating custom applications and projects. Through hands-on electronics and prototyping, students design and build interactive circuits, applying principles of electronics and engineering. Creative projects challenge them to design, build, and troubleshoot innovative solutions, fostering problem-solving skills. This experience helps students connect technology to real-world applications, recognizing how computing and electronics are used in fields like robotics, IoT, and creative technology.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "The Pi-top Kit empowers students to unlock the power of computing and creative technology. It’s not just about building a computer it’s about building the skills to innovate and explore the world of digital creation!",
  },
  {
    id: 6,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Quadcopter.jpg",
    title: "Quadcopter",
    description: "Advanced Level",
    buttonText: "Expand Your Horizons",
    details: "The Quadcopter STEM Kit is designed to give school students a hands-on introduction to drone technology. This kit includes all the components needed to assemble, program, and fly a quadcopter, allowing students to explore the principles of aerodynamics, electronics, and control systems. Through this engaging experience, students will learn how drones work and gain practical skills in assembly, programming, and piloting.",
    outcomeTitle: "Outcome",
    outcomeDescription: "By completing the Quadcopter STEM Kit projects, students will gain a strong understanding of drone technology and its applications. They will develop skills in programming, engineering, and aerodynamics while enhancing problem-solving and teamwork abilities. This kit empowers students to think innovatively and prepares them for future exploration in robotics, aviation, and technology.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure top performance and durability.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Expert advice and assistance are always available to help you succeed.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Community",
        description: "Join our passionate drone enthusiasts and share your experiences.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "Ready to take to the skies? With our Quadcopter, you can pilot your own drone and learn about flight in a super fun way! It’s easy to fly and gives you the perfect start to aerial adventures, whether you're snapping photos from above or racing with friends.",
  },
  {
    id: 19,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Autonomous%20AI%20Car.jpg",
    title: "Autonomous AI Car",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "The Magician Go by DOBOT is an innovative robotic arm designed to immerse students in hands-on robotics, automation, and AI learning. It offers an interactive way for students to explore robotics programming, control systems, and real-world applications, making it an ideal tool for classrooms and STEM programs.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Through practical tasks such as picking objects, drawing, and 3D printing, students gain hands-on experience with robotics, gaining valuable insights into automation and control systems. They also develop skills in AI and computer vision by working on tasks like object sorting and autonomous decision-making. Both visual and text-based programming help them expand their coding abilities, enhancing computational thinking and expertise. Tackling real-world challenges with Magician Go fosters problem-solving and critical thinking as students create innovative solutions. This experience highlights how robotics and AI are applied in various industries, including manufacturing, logistics, and the creative sector.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "The Magician Go project offers students a window into the future of robotics and automation. It’s not just a learning tool it’s a hands-on experience in bringing robotics to life, inspiring creativity and technological exploration!",
  },
  {
    id: 20,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Class%20VR%20Kit.jpg",
    title: "CLASS VR Kit",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "ClassVR is a powerful educational tool that uses virtual reality (VR) to transform the way students experience learning. By immersing students in virtual environments, ClassVR offers an engaging and interactive way to explore subjects ranging from history and science to art and geography, making abstract concepts tangible and real.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Students gain a deeper understanding of various subjects by immersing themselves in environments they might not otherwise experience, from ancient civilizations to the depths of the ocean. Virtual reality (VR) helps them visualize and comprehend complex concepts, making topics like anatomy, space exploration, and physics more accessible. By sparking curiosity and encouraging self-directed exploration, VR promotes inquiry-based learning. Immersive experiences also foster empathy and cultural awareness, allowing students to walk in others’ shoes. This approach connects classroom lessons to real-world contexts, making learning more relevant, engaging, and impactful.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "ClassVR isn’t just about technology it’s about creating new opportunities for exploration, understanding, and connection. With ClassVR, students aren’t just learning they’re experiencing the world in new and exciting ways!",
  },
  {
    id: 4,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Glider.jpg",
    title: "Glider",
    description: "",
    buttonText: "Experience the Art of Gliding",
    details: "The Glider Kit provides an exciting introduction to the basics of flight. With this kit, learners can build a lightweight, aerodynamic glider designed for smooth, unpowered flights. It covers important concepts such as lift, drag, and stability, helping students explore how air affects flight and how simple design choices can lead to longer, more stable glides.",
    outcomeTitle: "Outcome",
    outcomeDescription: "By building and experimenting with the Glider, participants will gain a practical understanding of fundamental aerodynamics and how forces like lift and drag influence movement. This project encourages creative thinking and problem-solving as they refine their designs for optimal performance, all while enjoying the thrill of seeing their glider soar effortlessly through the air.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure optimal performance and durability.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team provides advice and guidance to help you succeed.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Community",
        description: "Join our passionate gliding enthusiasts and share your experiences.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "Ever wanted to launch something into the sky and watch it glide? The Glider is a simple yet exciting project where you can explore the basics of flight and aerodynamics. It’s perfect for experimenting with different designs and seeing how far you can make it soar!",
  },
  {
    id: 18,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Bluetooth%20Control%20Car.jpg",
    title: "Bluetooth Control Car",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "The Bluetooth Control Car is a fun and educational kit designed for kids to explore basic electronics and wireless control. Using a simple Bluetooth connection, kids can control their car's movement through a smartphone or tablet, making it a hands-on way to learn about remote control systems. The kit is easy to assemble and offers an exciting introduction to programming and robotics.",
    outcomeTitle: "Outcome",
    outcomeDescription: "With the Bluetooth Control Car, kids will gain an understanding of wireless communication, motor control, and basic electronics. They will learn how to control the car's movement through a Bluetooth-enabled device, enhancing problem-solving skills and creativity. This project provides a fun and interactive way for children to explore STEM concepts while building their own remote-controlled car.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "Take control of your own car using just your phone! With the Bluetooth Control Car kit, you’ll learn how to program and drive a car remotely. It's a fun and interactive way to explore robotics and wireless technology!",
  },
  {
    id: 2,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/RC%20plane.jpg",
    title: "3D Printed RC Plane",
    description: "Intermediate Level",
    buttonText: "Elevate Your Skills",
    details: "The 3D Printed RC Plane Kit is a fun and educational tool designed specifically for school students. It provides everything needed to design, 3D print, and assemble a remote-controlled airplane. Through this hands-on project, students will not only learn about aerodynamics and flight mechanics but also how to work with 3D printing technology. It's the perfect blend of creativity and engineering for young innovators eager to build something that can actually take to the skies.",
    outcomeTitle: "Outcome",
    outcomeDescription: "By completing the 3D Printed RC Plane project, students will develop key skills in 3D design, printing, and assembly. They will also gain a practical understanding of how planes fly and how remote control systems work. This project encourages problem-solving, innovation, and technical skills, while giving students the unique opportunity to watch their creations.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure top performance and durability.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Expert advice and assistance are always available to help you succeed.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Community",
        description: "Join our passionate RC enthusiasts and share your experiences.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "Ever thought of designing and flying something you've built from scratch? The 3D Printed RC Plane lets you bring your own custom plane to life, straight from the 3D printer! It’s the perfect blend of creativity, technology, and aviation.",
  },
  {
    id: 22,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Hydroponics%20Aspara.jpg",
    title: "Aspara Nature Smart Grower",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "The Hydroponics Kit introduces students to sustainable agriculture by teaching them how to grow plants without soil. This hands-on project explores the science of hydroponics, where students learn how plants can thrive using water, nutrients, and innovative growth techniques. It’s a great way to connect biology, technology, and environmental science in a real-world context.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Students gain a clear understanding of how hydroponics works and its benefits for sustainable agriculture, exploring environmental science concepts such as water cycles, plant biology, and efficient farming techniques through hands-on projects. They integrate sensors and basic coding to automate processes like watering and nutrient management, connecting technology to environmental applications. By designing and troubleshooting hydroponic systems, students foster creativity and critical thinking, developing innovative solutions for agriculture and sustainability. This experience helps students understand how hydroponics can address real-world challenges, including food security, urban farming, and resource conservation.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "The Hydroponics Kit empowers students to explore sustainable agriculture and innovative growing techniques. It’s not just about growing plants it’s about growing knowledge and a passion for creating a more sustainable future!",
  },
  {
    id: 17,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Solar%20Tracking.jpg",
    title: "Solar Tracking Kit",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "The Solar Tracking Kit for Arduino is an engaging and educational tool designed to help users explore solar energy and electronics. This kit includes components like ambient light sensors, servos, a solar panel, and various other modules that allow you to build a device capable of tracking the sun and converting light into electricity. With added features such as a smartphone charging module, temperature and humidity sensors, a light sensor, and an LCD display, it offers a hands-on experience for teens, adults, and professionals. It is easy to assemble and program, making it ideal for learning about renewable energy, electronics, and programming.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Using the Solar Tracking Kit, learners will develop a strong understanding of how solar energy can be effectively captured through a tracking system. They will gain practical experience with Arduino programming, controlling sensors and servos, and working with electronics. The kit encourages problem-solving, creative thinking, and hands-on experimentation, while teaching important concepts in energy efficiency, automation, and basic mechanics. The extendable design also allows users to connect additional sensors and modules, promoting further exploration and customization.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "Harness the power of the sun with this Solar Tracking Kit! Learn how to build a system that follows the sun’s movement, maximizing energy efficiency. Perfect for students eager to explore renewable energy and innovative engineering.",
  },
  {
    id: 7,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Hexacopter.jpg",
    title: "Hexacopter",
    description: "Expert Level",
    buttonText: "Command the Skies",
    details: "The Hexacopter Kit offers an immersive experience for students interested in drone technology. This kit includes everything needed to build a six-rotor drone, providing enhanced stability and precise control compared to traditional quadcopters. As they work through the assembly, students will explore key concepts like multi-rotor flight dynamics, motor synchronization, and the basics of drone programming.",
    outcomeTitle: "Outcome",
    outcomeDescription: "By completing the Hexacopter project, learners will develop a deep understanding of drone mechanics and control systems. They will enhance their problem-solving abilities by learning how to manage flight stability and efficient maneuvering. This hands-on project offers a thorough introduction to modern drone technology, helping them build both practical and technical skills.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure top performance and durability.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Expert advice and assistance are always available to help you succeed.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Community",
        description: "Join our passionate drone enthusiasts and share your experiences.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "Take your drone skills to the next level with the Hexacopter. With six propellers, it’s more stable and offers a smoother flight experience. Learn about advanced drone technology and how to control more complex aerial machines.",
  },
  {
    id: 25,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/mBot%20Ranger.jpg",
    title: "mBot Ranger",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "The mBot Ranger is a versatile, 3-in-1 robot designed to spark curiosity and hands-on learning in robotics, coding, and engineering. Ideal for both beginners and advanced learners, mBot Ranger offers an exciting gateway to explore STEM concepts through creative projects and interactive challenges.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Students gain hands-on experience in mechanical design, building skills, and engineering concepts. They also develop coding skills by learning both visual and text-based programming to create custom programs for each robot configuration. Additionally, they explore sensor integration, using sensors for tasks such as line-following, obstacle detection, and balance maintenance. Each robot form presents unique challenges, enhancing students’ problem-solving abilities and adaptability. Through these activities, students gain insight into real-world robotics, seeing how coding, engineering, and sensors converge in autonomous vehicles, robotics applications, and smart technology.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "mBot Ranger encourages students to push boundaries and explore new frontiers in robotics and engineering. It’s not just a robot it’s a journey into the exciting world of innovation and creativity!",
  },
  {
    id: 12,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/WiFi%20Car.jpg",
    title: "Rover Car",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "The Rover Car Kit offers an engaging way for learners to build and program their own mobile rover. With components that include sensors and motors, participants will assemble and program the car to navigate and perform tasks, all while exploring key concepts in robotics, automation, and mobility. It’s a hands-on experience that bridges the gap between theory and real-world application.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Through the Rover Car project, participants will gain practical experience in robotics, learning how to integrate sensors for obstacle detection and design efficient navigation systems. This project enhances critical thinking and problem-solving skills while fostering creativity and a deep understanding of robotics and mechanical systems.",
    features: [
      {
        title: "Quality",
        description: "All materials are designed with safety in mind, ensuring a safe and enjoyable learning experience.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Experiment with different designs and functions to create a rover that's uniquely yours.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "Get ready to design your very own rover! This Rover Car project teaches you about robotics, coding, and mechanics as you build and program a car that can navigate all kinds of terrain. Perfect for young engineers looking to explore the world of autonomous vehicles.",
  },  
  {
    id: 28,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Sphero%20BOLT+.jpg",
    title: "Sphero BOLT+ Coding Robot",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "The Sphero BOLT+ is a versatile and educational robotic ball designed to teach students about coding, robotics, and STEM concepts in an engaging, hands-on way. Building on the success of the original Sphero, the BOLT+ adds enhanced features like a larger size, more advanced sensors, and improved connectivity, offering even more opportunities for creativity and learning. Sphero BOLT+ is designed for all skill levels, from beginners learning basic programming concepts to advanced students diving into more sophisticated coding and robotics.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Students will learn programming fundamentals such as logic, variables, loops, and functions through coding challenges on the Sphero Edu platform, using both block-based and text-based programming. The BOLT+ robot introduces advanced STEM concepts like motion dynamics, geometry, speed, and direction, while also exploring mathematical principles through sensor data. Students will design and troubleshoot challenges, enhancing their problem-solving and critical thinking skills. With built-in sensors for tilt, speed, and direction, the BOLT+ provides real-time data, allowing students to analyze and optimize robotic movement. This hands-on experience links to real-world applications in robotics, autonomous systems, drones, and sensor-based technologies, preparing students for future careers in tech fields.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "The Sphero BOLT+ is designed to inspire students to think like engineers and programmers through a fun and interactive robotic experience. It helps build the skills necessary for future careers in robotics, computer science, and engineering, while fostering creativity and teamwork. The robot’s capabilities extend beyond just simple movements, allowing students to experiment ",
  },
  {
    id: 8,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Cube%20SAT.jpg",
    title: "CubeSat",
    description: "Advanced Satellite Platform",
    buttonText: "Unlock Space Exploration",
    details: "The CubeSat Kit offers a hands-on experience for school students interested in satellite technology. This kit allows them to design, build, and test a mini-satellite similar to those used in space missions. Through practical activities, students learn about satellite components, data transmission, and sensor integration, gaining insights into how satellites help with communication, research, and exploration.",
    outcomeTitle: "Outcome",
    outcomeDescription: "By completing the CubeSat project, students gain real-world experience in building and programming satellite systems. They’ll learn how to collect and analyze data using sensors, understand the basics of satellite communication, and develop essential problem-solving and technical skills that can be applied to future projects in aerospace and technology.",
    features: [
      {
        title: "Quality",
        description: "We use advanced materials and technologies to ensure the reliability and performance of our CubeSats and CanSats.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Stay at the forefront of space technology with our cutting-edge satellite platforms and educational tools.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "Dreaming of space exploration? CubeSat lets you build a mini satellite that simulates real-life space missions. You’ll dive into aerospace engineering and get a taste of what it’s like to design technology that could one day orbit the Earth!",
  },
  {
    id: 21,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/CoDrone%20edu.jpg",
    title: "CoDrone EDU",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "CoDrone EDU is an educational drone designed to teach students programming, robotics, and engineering concepts in an engaging, hands-on way. This programmable drone offers an interactive learning experience by combining coding with real-world applications, giving students the ability to control and program the drone using both visual block coding and text-based coding languages.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Students learn to write code to control a drone, progressing from basic block coding to text-based programming in Python as they advance. They explore key STEM concepts like force, gravity, and motion, gaining an understanding of how drones fly and the role sensors play in controlling flight. Through designing flight paths, obstacle courses, and autonomous behaviors, students practice creativity and critical thinking while learning to troubleshoot and debug their programs. This experience provides insight into the growing field of drones and autonomous systems, preparing students for future careers in robotics, aerospace engineering, and other technology-driven industries.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "CoDrone EDU empowers students with the tools they need to explore the world of robotics and coding through hands-on experience. It provides an exciting, interactive way to understand complex technical concepts and gives students a tangible outcome (the drone’s flight) for their coding efforts. Through this, students are not just learning to program they’re engaging with the technology shaping the future.",
  },
  {
    id: 15,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/MH%20Home%20Automation%20Kit.jpg",
    title: "MH Home Automation Kit",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "The MH Home Automation Kit is a fantastic way for kids to learn about electronics and smart home technology. Using laser-cut wood boards, sensors, actuators, and a control board, this kit lets you build your own model smart house. Through hands-on assembly and exploration, you’ll gain a solid understanding of how modern smart homes work.",
    outcomeTitle: "Outcome",
    outcomeDescription: "With the MH Home Automation Kit, you’ll learn how to integrate sensors for lighting, motion detection, and even soil moisture monitoring. You’ll also discover how to set up a gas leak alert system, control everything with a remote app, and explore the basics of coding and automation. This kit is perfect for learning about electronics, Arduino programming, and how to turn smart home ideas into practical applications.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "Turn your home into a smart home with the MH Home Automation Kit! This kit teaches you how to control lights, fans, and other devices using sensors and a smartphone. You’ll learn the basics of home automation and how smart technology can make life easier. Whether it’s turning off the lights with a voice command or setting up automatic timers, this kit is perfect for students who love gadgets and want to explore how technology can transform everyday tasks. Step into the future of home living with this fun and educational  project!",
  },
  {
    id: 3,
    productImage: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Avanti%20S.jpg",
    title: "Fighter EDF RC Plane",
    description: "Advanced Level",
    buttonText: "Master the Skies",
    details: "The Fighter EDF RC Plane Kit is perfect for those eager to explore high-speed, jet-like flight. With its electric ducted fan (EDF) system, this kit allows aspiring builders to create a fighter-style plane that mimics real-world jet propulsion. The project introduces key concepts in aerodynamics, thrust, and control, making it an exciting way to learn about advanced aircraft mechanics while assembling a high-performance remote-controlled plane.",
    outcomeTitle: "Outcome",
    outcomeDescription: "By completing the Fighter EDF RC Plane project, builders will gain practical insights into jet propulsion, flight dynamics, and precision control. This hands-on experience enhances technical problem-solving skills and provides a deeper understanding of aircraft maneuverability. The end result is not just an educational journey but also the thrill of piloting a custom-built, fast-flying RC plane.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure top performance and durability.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2001.png",
      },
      {
        title: "Support",
        description: "Expert advice and assistance are always available to help you succeed.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2002.png",
      },
      {
        title: "Community",
        description: "Join our passionate RC enthusiasts and share your experiences.",
        icon: "https://ik.imagekit.io/mhcockpit1/MHB/Product%20Details%20Page/Icon%2003.png",
      },
    ],
    conclusionText: "Ready to pilot a jet? The Fighter EDF RC Plane is for students who love speed and precision. You’ll get hands-on with the mechanics of flight and experience the thrill of controlling a high-speed jet right in your backyard.",
  },

];

export default products;
