import React from "react";
import FadeInSectionDownToUp from "../../Components/FadeAnimations/FadeInSectionDownToUp.js";

const VissionAndMission = () => {
  return (
    <section className="bg-white dark:bg-black">
      <div className="flex justify-center items-center">
        <div className="py-32 flex justify-center items-center max-w-5xl">
          <div className="shadow-2xl dark:shadow-2xl dark:shadow-gray-500 dark:bg-gray-500 dark:bg-opacity-50 rounded-3xl overflow-hidden px-10 sm:px-20 py-20 sm:py-36" >
            <h1 className="max-sm:text-4xl text-5xl font-semibold text-center">
              <FadeInSectionDownToUp>
                <span className="text-black dark:text-white">Our </span><span className="text-gray-500">Vision</span>
              </FadeInSectionDownToUp>
            </h1>
            <div className="flex justify-center items-center gap-10 py-10 overflow-x-auto" >
              <img className="rounded-3xl w-60" src="https://ik.imagekit.io/mhcockpit1/MHB/Vision%20&%20Mission/Vision%2000.jpg" alt="Vision-picture" />
              <img className="rounded-3xl w-60" src="https://ik.imagekit.io/mhcockpit1/MHB/Vision%20&%20Mission/Vision%2001.jpg" alt="Vision-picture" />
              <img className="rounded-3xl w-60" src="https://ik.imagekit.io/mhcockpit1/MHB/Vision%20&%20Mission/Vision%2003.jpg" alt="Vision-picture" />
            </div>
            <FadeInSectionDownToUp>
              <div className="text-justify pt-10 xl:pt-10 max-sm:pt-5text-black dark:text-white">
                <p>
                  Our vision at MH Intellect is to be a global leader in technology
                  driven education, inspiring young minds to become pioneers and
                  innovators. We aim to create an inclusive learning environment
                  where students are equipped with the knowledge and skills
                  necessary to excel in the fields of Artificial Intelligence,
                  Machine Learning, Robotics, and other emerging technologies.
                  Through our commitment to excellence and innovation, we strive to
                  build a brighter future where technology enhances the quality of
                  life and drives sustainable progress.
                </p>
              </div>
            </FadeInSectionDownToUp>
          </div>
        </div>
      </div>
      <div className="container mx-auto max-w-5xl px-5 py-20">
        <div className="grid grid-cols-1 sm:grid-cols-2 grid-rows-1 gap-0 sm:gap-10 md:gap-20 pb-16">
          <div className="flex justify-center items-center">
            <div className="pr-5">
              <img
                className="rounded-2xl"
                src="https://ik.imagekit.io/mhcockpit1/MHB/Vision%20&%20Mission/MISSION%2001.jpg"
                alt="Mission-picture"
              />
            </div>
            <div className="flex-row justify-center items-center mb-20">
              <div className="pb-5">
                <img
                  className="rounded-2xl"
                  src="https://ik.imagekit.io/mhcockpit1/MHB/Vision%20&%20Mission/MISSION%2002.jpg"
                  alt="Mission-picture"
                />
              </div>
              <div>
                <img
                  className="rounded-2xl"
                  src="https://ik.imagekit.io/mhcockpit1/MHB/Vision%20&%20Mission/MISSION%2003.jpg"
                  alt="Mission-picture"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center text-left">
            <FadeInSectionDownToUp>
              <div>
                <h1 className="max-sm:text-4xl text-5xl font-semibold mb-7 text-black dark:text-white">
                  Our <span className="text-gray-500">Mission</span>{" "}
                </h1>
                <p className="text-justify text-black dark:text-white">
                  At MH Intellect, we believe in the transformative power of
                  education. Our mission is to inspire the next generation of
                  innovators and leaders by making advanced technology education
                  fun, engaging, and accessible. By equipping young minds with
                  the skills and knowledge needed to excel in the 21st century,
                  we aim to build a brighter future where life is easier, safer,
                  and better for all.
                </p>
              </div>
            </FadeInSectionDownToUp>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 grid-rows-1 gap-14 md:gap-20">
          <div className="">
            <FadeInSectionDownToUp>
              <h1 className="text-4xl font-semibold mb-7 text-black dark:text-white">
                Building a <br /> Nation of{" "}
                <span className="text-gray-500">Innovators.</span>
              </h1>
              <p className="text-justify text-black dark:text-white">
                We are committed to being a game changer in education, fostering
                a culture of innovation and critical thinking. Our programs are
                designed to not only impart technical skills but also to instill
                a sense of curiosity and a passion for problem-solving. By doing
                so, we are preparing today's students to become tomorrow's
                leaders, capable of driving national progress and technological
                advancement.
              </p>
            </FadeInSectionDownToUp>
          </div>
          <div>
            <FadeInSectionDownToUp>
              <h1 className="text-4xl font-semibold mb-7 text-black dark:text-white">
                A <span className="text-gray-500">Safer</span> <br /> and Better
                <span className="text-gray-500"> Future.</span>
              </h1>
              <p className="text-justify text-black dark:text-white">
                Through our comprehensive curriculum and hands-on learning
                experiences, MH Intellect is dedicated to making life easier and
                safer. We believe that by empowering young minds with the right
                tools and knowledge, we can contribute to creating a safer and
                more efficient world, where technology serves humanity and
                enhances the quality of life.
              </p>
            </FadeInSectionDownToUp>
          </div>
        </div>
        {/* <div className="py-32 flex justify-center items-center">
          <div className="shadow-2xl rounded-3xl border-2 overflow-hidden px-10 sm:px-20 py-20 sm:py-36" >
            <h1 className="max-sm:text-4xl text-5xl font-semibold text-center ">
              Our <span className="text-gray-500">Vision</span>
            </h1>
          <div className="flex justify-center items-center gap-10 py-10 overflow-x-auto" >
            <img className="rounded-3xl w-60" src="/Vision 00.jpg" alt="Vision-picture"/>
            <img className="rounded-3xl w-60" src="/Vision 01.jpg" alt="Vision-picture"/>
            <img className="rounded-3xl w-60" src="/Vision 03.jpg" alt="Vision-picture"/>
          </div> 
          <FadeInSectionDownToUp>
          <div className="text-center pt-10 xl:pt-10 max-sm:pt-5">
            <p>
              Our vision at MH Intellect is to be a global leader in technology
              driven education, inspiring young minds to become pioneers and
              innovators. We aim to create an inclusive learning environment
              where students are equipped with the knowledge and skills
              necessary to excel in the fields of Artificial Intelligence,
              Machine Learning, Robotics, and other emerging technologies.
              Through our commitment to excellence and innovation, we strive to
              build a brighter future where technology enhances the quality of
              life and drives sustainable progress.
            </p>
          </div> 
          </FadeInSectionDownToUp>
         </div> 
        </div> */}
      </div>
    </section>
  );
};

export default VissionAndMission;
