import React, { useEffect, useState } from 'react'
import Marquee from 'react-fast-marquee';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';
import GalleryModal from '../../Components/Gallery/GalleryModal';
import Video1 from '../../Components/Video/Video1';
import { ArrowUp } from "lucide-react";

const images1 = Array.from({ length: 112 }, (_, i) => ({
    src: `https://ik.imagekit.io/mhcockpit1/MHB/Gallery/Programs/1/${i + 1}.jpg`,
    alt: 'Gallery',
}));

const images2 = Array.from({ length: 68 }, (_, i) => ({
    src: `https://ik.imagekit.io/mhcockpit1/MHB/Gallery/Programs/1/${i + 113}.jpg`,
    alt: 'Gallery',
}));

const images3 = Array.from({ length: 53 }, (_, i) => ({
    src: `https://ik.imagekit.io/mhcockpit1/MHB/Gallery/Programs/1/${i + 181}.jpg`,
    alt: 'Gallery',
}));

const indicesToRemove = [12, 15, 8, 9, 25, 30, 31, 32, 57, 81, 85, 92, 94, 96, 98, 101, 103, 104];

const GalleryImages = [...images1, ...images3, ...images2];

const filteredGalleryImages = GalleryImages.filter((_, index) => !indicesToRemove.includes(index + 1));

const ImageColumn = ({ src, alt, onClick }) => (
    <div className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/3 p-4 max-sm:p-0">
        <center>
            <div className="p-3">
                <FadeInSectionDownToUp>
                    <img src={src} alt={alt} onClick={() => onClick(src)} className="w-full h-auto rounded-3xl xl:rounded-3xl max-sm:rounded-xl cursor-pointer" />
                </FadeInSectionDownToUp>
            </div>
        </center>
    </div>
);

export default function GalleryNew() {

    const [modalImageSrc, setModalImageSrc] = useState(null);
    const [playingVideo, setPlayingVideo] = useState(null);
    const [isVisible, setIsVisible] = useState(false);

    // Show button when page is scrolled down
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    // Scroll to top function
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handlePlay = (videoSrc) => {
        setPlayingVideo(videoSrc);
    };

    // State to manage the active tab
    const [activeTab, setActiveTab] = useState('photos');

    // Function to handle tab clicks
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const openModal = (src) => {
        setModalImageSrc(src);
    };

    const closeModal = () => {
        setModalImageSrc(null);
    };

    const otherVideos = [

        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Innauguration%20Video.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect%20KRM%202%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Inauguration%200ct%2026.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect_Testimonial%20KRM%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Gallery/Learning%20Consortium%202.0.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect%20KRM%202%20New.png',
        },

    ]

    const TestimonialVideos = [

        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Landing_Page/Testimony%20-%20ZARA.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect%20KRM%202%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Landing_Page/Testimony%20-%20NEEV.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect_Testimonial%20KRM%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Landing_Page/Testimony%20-%20Bhuvaneshwarya.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect%20KRM%202%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Tes_6.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect%20KRM%202%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Tes_3.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect_Testimonial%20KRM%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Tes_5.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect%20KRM%202%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Tes_4.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect_Testimonial%20KRM%20New.png',
        }, {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Tes_1.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect%20KRM%202%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Tes_2.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect_Testimonial%20KRM%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Tes_7.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect%20KRM%202%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Tes_8.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect_Testimonial%20KRM%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Tes_9.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect_Testimonial%20KRM%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/MH%20Intellect%20KRM%202%20New.mp4',
            thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect%20KRM%202%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/MH%20Intellect_Testimonial%20KRM%20New.mp4',
            thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect_Testimonial%20KRM%20New.png',
        },

    ]

    return (
        <div className='bg-white dark:bg-black'>
            <div className="text-9xl xl:text-6xl sm:text-5xl max-sm:text-4xl py-20 from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent text-center">
                <h1>Our Clients.</h1>
            </div>
            <div className="px-10 xl:px-10 max-sm:px-0">
                <Marquee speed={200}>
                    <img className='w-72 mx-10 my-5 max-sm:w-36 max-sm:py-5 max-sm:px-5 rounded-xl' src='https://ik.imagekit.io/mhcockpit1/MHB/School%20&%20College%20LOGO/ASPAM.jpg' alt='LOGO' />
                    <img className='w-72 mx-10 my-5 max-sm:w-36 max-sm:py-5 max-sm:px-5 rounded-xl' src='https://ik.imagekit.io/mhcockpit1/MHB/School%20&%20College%20LOGO/good%20word%20public.jpg' alt='LOGO' />
                    <img className='w-72 mx-10 my-5 max-sm:w-36 max-sm:py-5 max-sm:px-5 rounded-xl' src='https://ik.imagekit.io/mhcockpit1/MHB/School%20&%20College%20LOGO/krm%20matric.jpg' alt='LOGO' />
                    <img className='w-72 mx-10 my-5 max-sm:w-36 max-sm:py-5 max-sm:px-5 rounded-xl' src='https://ik.imagekit.io/mhcockpit1/MHB/School%20&%20College%20LOGO/kaligi%20ranganathan%20montford.jpg' alt='LOGO' />
                    <img className='w-72 mx-10 my-5 max-sm:w-36 max-sm:py-5 max-sm:px-5 rounded-xl' src='https://ik.imagekit.io/mhcockpit1/MHB/School%20&%20College%20LOGO/krm%20public.jpg' alt='LOGO' />
                    <img className='w-72 mx-10 my-5 max-sm:w-36 max-sm:py-5 max-sm:px-5 rounded-xl' src='https://ik.imagekit.io/mhcockpit1/MHB/School%20&%20College%20LOGO/jamal.jpg' alt='LOGO' />
                    <img className='w-72 mx-10 my-5 max-sm:w-36 max-sm:py-5 max-sm:px-5 rounded-xl' src='https://ik.imagekit.io/mhcockpit1/MHB/School%20&%20College%20LOGO/vels%20logo.jpg' alt='LOGO' />
                    <img className='w-72 mx-10 my-5 max-sm:w-36 max-sm:py-5 max-sm:px-5 rounded-xl' src='https://ik.imagekit.io/mhcockpit1/MHB/School%20&%20College%20LOGO/malla%20reddy.jpg' alt='LOGO' />
                    <img className='w-72 mx-10 my-5 max-sm:w-36 max-sm:py-5 max-sm:px-5 rounded-xl' src='https://ik.imagekit.io/mhcockpit1/MHB/School%20&%20College%20LOGO/crescent.jpg' alt='LOGO' />
                </Marquee>
            </div>
            <div className="border-gray-200 dark:border-gray-700 flex justify-center items-center py-10">
                <ul className="flex justify-center items-center text-3xl xl:text-3xl sm:text-2xl max-sm:text-lg font-medium text-center gap-28 xl:gap-28 sm:gap-5 max-sm:gap-10" role="tablist">
                    <li className="me-2" role="presentation">
                        <button
                            className={`inline-block border-b-2 rounded-t-lg ${activeTab === 'photos' ? 'border-black' : 'border-transparent'}`}
                            onClick={() => handleTabClick('photos')}
                            type="button"
                            role="tab"
                            aria-controls="photos"
                            aria-selected={activeTab === 'photos'}
                        >
                            Photos
                        </button>
                    </li>
                    <li className="me-2" role="presentation">
                        <button
                            className={`inline-block border-b-2 rounded-t-lg ${activeTab === 'videos' ? 'border-black' : 'border-transparent'}`}
                            onClick={() => handleTabClick('videos')}
                            type="button"
                            role="tab"
                            aria-controls="videos"
                            aria-selected={activeTab === 'videos'}
                        >
                            Videos
                        </button>
                    </li>
                    <li className="me-2" role="presentation">
                        <button
                            className={`inline-block border-b-2 rounded-t-lg ${activeTab === 'testimonials' ? 'border-black' : 'border-transparent'}`}
                            onClick={() => handleTabClick('testimonials')}
                            type="button"
                            role="tab"
                            aria-controls="testimonials"
                            aria-selected={activeTab === 'testimonials'}
                        >
                            Testimonials
                        </button>
                    </li>
                </ul>
            </div>
            <div>
                {activeTab === 'photos' && (
                    <div className="" role="tabpanel" aria-labelledby="photos-tab">
                        <div>
                            <div className="px-10 xl:px-10 max-sm:px-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-wrap">
                                        {filteredGalleryImages.map((image, index) => (
                                            <ImageColumn key={index} src={image.src} alt={image.alt} onClick={openModal} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {activeTab === 'videos' && (
                    <div className="" role="tabpanel" aria-labelledby="videos-tab">
                        <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-5 px-10 max-sm:px-5 pb-5">
                            {otherVideos.map((video, index) => (
                                <div key={index}>
                                    <FadeInSectionDownToUp>
                                        <Video1
                                            videoSrc={video.videoSrc}
                                            thumbnailSrc={video.thumbnailSrc}
                                            className="rounded-3xl"
                                            isPlaying={playingVideo === video.videoSrc}
                                            onPlay={handlePlay}
                                        />
                                    </FadeInSectionDownToUp>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {activeTab === 'testimonials' && (
                    <div className="" role="tabpanel" aria-labelledby="testimonials-tab">
                        <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-5 px-10 max-sm:px-5 pb-5">
                            {TestimonialVideos.map((video, index) => (
                                <div key={index}>
                                    <FadeInSectionDownToUp>
                                        <Video1
                                            videoSrc={video.videoSrc}
                                            thumbnailSrc={video.thumbnailSrc}
                                            className="rounded-3xl"
                                            isPlaying={playingVideo === video.videoSrc}
                                            onPlay={handlePlay}
                                        />
                                    </FadeInSectionDownToUp>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <GalleryModal
                isOpen={!!modalImageSrc}
                imageSrc={modalImageSrc}
                onClose={closeModal}
            />
            <div className="flex justify-end items-end max-sm:pb-10 sticky bottom-14 max-sm:bottom-0 pr-4 text-black dark:text-white">
                {isVisible && (
                    <button
                        onClick={scrollToTop}
                        className="flex xl:absolute sm:absolute bg-slate-600 dark:bg-slate-500 bg-opacity-40 px-1 py-1 rounded-xl"
                    >
                        <ArrowUp />
                    </button>
                )}
            </div>
        </div>
    )
}
