import React from 'react'
import FadeInSectionDownToUp from '../../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function DIYProjects() {
    return (
        <div>
            <div>
                <img src='https://ik.imagekit.io/mhcockpit1/MHB/Projects/DIY%20Projects.jpg' alt='DIY Projects' />
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <h1 className='text-center font-bold text-[#565EFF] text-4xl py-10'>DIY Projects</h1>
                </FadeInSectionDownToUp>
            </div>
            <div className='space-y-5 pb-10'>
                <FadeInSectionDownToUp>
                    <p className='px-40 xl:px-40 sm:px-36 max-sm:px-5 text-xl max-sm:text-lg text-center'>
                        In DIY Electronics, students will have the opportunity to turn their ideas into functioning prototypes through engaging, hands-on projects. They’ll develop essential skills like problem-solving, collaboration, and technical design, turning abstract concepts into real-world applications.
                    </p>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <p className='px-48 xl:px-48 sm:px-40 max-sm:px-5 text-xl max-sm:text-lg text-center'>
                        By working in teams, students will plan, build, and refine electronic projects using components such as LEDs, sensors, and microcontrollers. With a focus on creativity and practical learning, each group will bring their unique designs to life, building experience in teamwork, critical thinking, and electronics prototyping—all while enjoying the thrill of seeing their creations in action.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <p className='text-center text-4xl space-x-2'>
                        <span className=''>Student's</span>
                        <span className='font-bold text-[#E355FF]'>Benefits</span>
                    </p>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <p className='text-center text-xl'>
                        from our projects
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex flex-wrap justify-center mt-10'>
                <div className='relative w-72 h-72 m-3'>
                    <div className='absolute inset-0 bg-cover bg-center' style={{ backgroundImage: "url('https://ik.imagekit.io/mhcockpit1/MHB/Projects/Sticky%20Notes.png')" }} />
                    <div className='relative p-5 flex items-center justify-center h-full'>
                        <FadeInSectionDownToUp>
                            <p className='text-white text-start px-5 -skew-y-6'>
                                <b>Beginner Program</b><br />
                                Gain hands-on experience in electronics, engineering, and design through creative projects that encourage learning by experimenting and exploring new ideas.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
                <div className='relative w-72 h-72 m-3'>
                    <div className='absolute inset-0 bg-cover bg-center' style={{ backgroundImage: "url('https://ik.imagekit.io/mhcockpit1/MHB/Projects/Sticky%20Notes%20Pink.png')" }} />
                    <div className='relative p-5 flex items-center justify-center h-full'>
                        <FadeInSectionDownToUp>
                            <p className='text-white text-start px-5 -skew-y-6'>
                                <b>Intermediate Program</b><br />
                                Take on challenges like constructing robots or designing gadgets, pushing technical abilities and engaging in innovative, hands-on activities.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
                <div className='relative w-72 h-72 m-3'>
                    <div className='absolute inset-0 bg-cover bg-center' style={{ backgroundImage: "url('https://ik.imagekit.io/mhcockpit1/MHB/Projects/Sticky%20Notes.png')" }} />
                    <div className='relative p-5 flex items-center justify-center h-full'>
                        <FadeInSectionDownToUp>
                            <p className='text-white text-start px-5 -skew-y-6'>
                                <b>Advanced Program</b><br />
                                Develop problem-solving and technical skills by designing, building, and refining prototypes, gaining insights into the engineering and technology development process.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <p className="text-4xl text-center pt-20">
                        <span className="text-black underline decoration-[#565EFF] px-1">Potential</span>
                        <b className="text-[#565EFF] font-bold px-1">Scope.</b>
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-10 xl:px-0 sm:px-36 max-sm:px-5'>
                <FadeInSectionDownToUp>
                    <p className='text-xl text-center font-bold'>
                        {/* The <span className='text-[#565EFF]'>DIY</span> project */}
                        “Creativity is intelligence having fun; let’s turn imagination into reality!”
                    </p>
                    {/* <p className='text-xl text-center'>
                        has a broad future scope and great potential in a number of sectors, including
                    </p> */}
                </FadeInSectionDownToUp>
            </div>
            <div className='py-10'>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='py-2 pl-48 xl:pl-48 sm:pl-36 max-sm:pl-5 text-xl xl:text-xl max-sm:text-lg font-bold text-[#565EFF]'>
                            Crafting Your Ideas
                        </p>
                        <p className='py-2 px-48 xl:px-48 sm:px-36 max-sm:px-5 text-xl xl:text-xl max-sm:text-lg text-justify'>
                            In this project, students engage in hands-on activities to create their own DIY projects. They explore various materials and techniques, allowing their creativity to shine as they bring their unique ideas to life.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='py-2 pl-48 xl:pl-48 sm:pl-36 max-sm:pl-5 text-xl xl:text-xl max-sm:text-lg font-bold text-[#E355FF]'>
                            Explore Your Interests
                        </p>
                        <p className='py-2 px-48 xl:px-48 sm:px-36 max-sm:px-5 text-xl xl:text-xl max-sm:text-lg text-justify'>
                            Students discover a variety of DIY opportunities, from home decor to functional gadgets. This exploration encourages them to experiment and find what they enjoy most, all while learning valuable skills along the way.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='py-2 pl-48 xl:pl-48 sm:pl-36 max-sm:pl-5 text-xl xl:text-xl max-sm:text-lg font-bold text-[#565EFF]'>
                            Share Your Creations
                        </p>
                        <p className='py-2 px-48 xl:px-48 sm:px-36 max-sm:px-5 text-xl xl:text-xl max-sm:text-lg text-justify'>
                            Showcasing their DIY projects provides students with the chance to gather feedback and inspire one another. This collaborative environment celebrates innovation and encourages continuous learning.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
        </div>
    )
}
