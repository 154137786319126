import React, { useState } from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'
import LabCardAnim from '../../Components/Lab/Card Anim/LabCardAnim'
import { ArrowDown } from 'lucide-react'
import Video1 from '../../Components/Video/Video1'

export default function Lab() {

  const ExperimentVideos = [

    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Innauguration%20Video.mp4',
      thumbnailSrc: '',
    },
    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/3D%20Printing%20Machine%20-%20UAE.mp4',
      thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/3D%20Printer.png',
    },
    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Line%20Following%20Robo%20Car.mp4',
      thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/Line%20Following%20Bot.jpg',
    },
    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Smart%20Gas%20Leakage%20Detector.mp4',
      thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/Smart%20Gas%20Detector.jpg',
    },
    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/UAE_INTELLECT%20EXP%205%20Rose.mp4',
      thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/Rose%20Experiment.jpg',
    },
    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/UAE_INTELLECT%20EXP%204%20Clouds.mp4',
      thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/Clouds.jpg',
    },
    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/UAE_INTELLECT%20EXP%207%20Balloon.mp4',
      thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/Balloon.jpg',
    },
    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/MH%20Intellect%20Collage%20Final.mp4',
      thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/Collage%20Thumbnail.png',
    },
    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/UAE_INTELLECT%20EXP%202%20Balls.mp4',
      thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/Ball%20Experiment.jpg',
    },
    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/MHC%20AEROCLUB.mp4',
      thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/Sonam%20Mam.png',
    },
    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/MH_Intellect%20Kabir.mp4',
      thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/Kabeer.png',
    },
    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/UAE_INTELLECT%20EXP%206%20Eraser.mp4',
      thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/Eraser.jpg',
    },
    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/UAE_INTELLECT%20EXP%201%20Grape.mp4',
      thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/Grape%20Experiment.jpg',
    },
    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/UAE_INTELLECT%20EXP%203%20Anti%20Freezing%20Liquid.mp4',
      thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/Anti%20Freezing%20Liquid.jpg',
    },
    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/UAE_INTELLECT%20EXP%208%20Pipe.mp4',
      thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/Pipe.jpg',
    },
    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Micro%20Weather%20Station.mp4',
      thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/Micro%20weather%20station.jpg',
    },
    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Monocopter.mp4',
      thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/monocoptor.jpg',
    },
    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Obstacle%20Avoidance%20Car.mp4',
      thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/Obstacle%20Car.jpg',
    },
    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Solar%20panel.mp4',
      thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/Solar%20panel.jpg',
    },
    {
      videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Light%20controller.mp4',
      thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/Light%20Controller.jpg',
    },

  ]

  const [playingVideo, setPlayingVideo] = useState(null);

  const handlePlay = (videoSrc) => {
    setPlayingVideo(videoSrc);
  };

  return (
    <div className='bg-white dark:bg-black'>
      <div className='px-60 max-sm:px-10'>
        <FadeInSectionDownToUp>
          <h1 className="text-9xl xl:text-9xl max-sm:text-6xl py-20 from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent text-center">
            Experience<br />Center.
          </h1>
        </FadeInSectionDownToUp>
      </div>
      <div className='xl:block lg:block sm:hidden max-sm:hidden'>
        <div className='py-2 text-lg animate-bounce text-black dark:text-white flex justify-center'>
          <div>
            <p>Click Below</p>
          </div>
          <div>
            <ArrowDown />
          </div>
        </div>
        <LabCardAnim />
      </div>
      <div className='flex justify-center items-center xl:hidden lg:hidden sm:block max-sm:block'>
        <div className='flex justify-center items-center'>
          <div className='px-2 py-2'>
            <a href='/stem-and-robotics'>
              <img className='rounded-3xl w-32' src='https://ik.imagekit.io/mhcockpit1/MHB/Lab/SR.jpg' alt='Lab' />
            </a>
          </div>
          <div className='px-2 py-2'>
            <a href='/aviation-and-aero-lab'>
              <img className='rounded-3xl w-32' src='https://ik.imagekit.io/mhcockpit1/MHB/Lab/Aerospace.jpg' alt='Lab' />
            </a>
          </div>
          <div className='px-2 py-2'>
            <a href='/ai-and-ml'>
              <img className='rounded-3xl w-32' src='https://ik.imagekit.io/mhcockpit1/MHB/Lab/AIML.jpg' alt='Lab' />
            </a>
          </div>
        </div>
      </div>
      <div className='pt-36'>
        <p className='text-black dark:text-white text-3xl text-center'>
          Learn . Think . Innovate
        </p>
      </div>
      <div className="pt-5">
        <FadeInSectionDownToUp>
          <h1 className='text-8xl xl:text-7xl lg:text-6xl max-sm:text-5xl sm:text-6xl py-10 xl:py-10 max-sm:py-2 text-center leading-[80px] from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent'>
            Our Experiments.
          </h1>
        </FadeInSectionDownToUp>
      </div>
      {/* <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-5 px-10 max-sm:px-5">
        {ExperimentVideos.map((video, index) => (
          <div key={index}>
            <FadeInSectionDownToUp>
              <Video_1
                videoSrc={video.videoSrc}
                thumbnailSrc={video.thumbnailSrc}
                className="rounded-3xl"
              />
            </FadeInSectionDownToUp>
          </div>
        ))}
      </div> */}
      <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-5 px-10 max-sm:px-5">
        {ExperimentVideos.map((video, index) => (
          <div key={index}>
            <FadeInSectionDownToUp>
              <Video1
                videoSrc={video.videoSrc}
                thumbnailSrc={video.thumbnailSrc}
                className="rounded-3xl"
                isPlaying={playingVideo === video.videoSrc}
                onPlay={handlePlay}
              />
            </FadeInSectionDownToUp>
          </div>
        ))}
      </div>
      <div className='pt-36 px-56 xl:px-56 sm:px-20 max-sm:px-10'>
        <FadeInSectionDownToUp>
          <p className='text-black dark:text-white text-8xl max-sm:text-5xl'>
            Flight<br />Simulator.
          </p>
        </FadeInSectionDownToUp>
        <img className='rounded-[90px] xl:rounded-[90px] py-10' src='https://ik.imagekit.io/mhcockpit1/MHB/Lab/FS.jpg' alt='Flight Simulator' />
      </div>
      <div className='px-56 xl:px-56 sm:px-20 max-sm:px-10 pb-10 flex justify-end max-sm:justify-start text-black dark:text-white'>
        <FadeInSectionDownToUp>
          <p className='text-justify'>
            A flight simulator is a sophisticated tool designed to replicate the experience of flying an aircraft. It provides a virtual environment where users can practice piloting skills and procedures without the risks associated with actual flight. Flight simulators range from basic desktop software to highly advanced systems used for professional training. They often include realistic controls, detailed cockpit instruments, and accurate flight dynamics to mimic real-world flying conditions. Pilots use these simulators for training, to hone skills, and to practice emergency scenarios. Additionally, flight simulators are used in the design and testing of aircraft, as well as for entertainment and hobby purposes.
          </p>
        </FadeInSectionDownToUp>
      </div>
    </div>
  )
}
