import React from 'react'
import FadeInSectionDownToUp from '../../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function ARVRTech() {
    return (
        <div>
            <div>
                <img src='https://ik.imagekit.io/mhcockpit1/MHB/Projects/AR%20&%20VR%20tech%20.jpg' alt='AR & VR Tech' />
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <h1 className='text-center font-bold text-[#565EFF] text-4xl py-10'>AR & VR Tech</h1>
                </FadeInSectionDownToUp>
            </div>
            <div className='space-y-5 pb-10'>
                <FadeInSectionDownToUp>
                    <p className='px-40 xl:px-40 sm:px-36 max-sm:px-5 text-xl max-sm:text-lg text-center'>
                        Explore the exciting world of augmented and virtual reality, where students can create their own unique experiences. They’ll work with 3D environments, learn how to use sensors, and discover how digital elements can merge with the real world.
                    </p>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <p className='px-48 xl:px-48 sm:px-40 max-sm:px-5 text-xl max-sm:text-lg text-center'>
                        The AR & VR Tech project is tailored for school students, introducing them to the fun and creative side of AR and VR. Through activities like 3D modeling and sensor integration, they’ll build interactive environments and virtual worlds. Using simple AR and VR tools, students will gain hands-on experience designing and interacting with digital content, boosting their creativity and problem-solving skills while learning about advanced technology.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <p className='text-center text-4xl space-x-2'>
                        <span className=''>Student's</span>
                        <span className='font-bold text-[#E355FF]'>Benefits</span>
                    </p>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <p className='text-center text-xl'>
                        from our projects
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex flex-wrap justify-center mt-10'>
                <div className='relative w-72 h-72 m-3'>
                    <div className='absolute inset-0 bg-cover bg-center' style={{ backgroundImage: "url('https://ik.imagekit.io/mhcockpit1/MHB/Projects/Sticky%20Notes.png')" }} />
                    <div className='relative p-5 flex items-center justify-center h-full'>
                        <FadeInSectionDownToUp>
                            <p className='text-white text-start px-5 -skew-y-6'>
                                <b className=''>Beginner Program</b><br />
                                AR and VR make learning more interactive, engaging, and easier to understand through virtual exploration and simulations.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
                <div className='relative w-72 h-72 m-3'>
                    <div className='absolute inset-0 bg-cover bg-center' style={{ backgroundImage: "url('https://ik.imagekit.io/mhcockpit1/MHB/Projects/Sticky%20Notes%20Pink.png')" }} />
                    <div className='relative p-5 flex items-center justify-center h-full'>
                        <FadeInSectionDownToUp>
                            <p className='text-white text-start px-5 -skew-y-6'>
                                <b className=''>Intermediate Program</b><br />
                                Students can create virtual simulations and AR apps, blending real-world data with virtual environments.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
                <div className='relative w-72 h-72 m-3'>
                    <div className='absolute inset-0 bg-cover bg-center' style={{ backgroundImage: "url('https://ik.imagekit.io/mhcockpit1/MHB/Projects/Sticky%20Notes.png')" }} />
                    <div className='relative p-5 flex items-center justify-center h-full'>
                        <FadeInSectionDownToUp>
                            <p className='text-white text-start px-5 -skew-y-6'>
                                <b className=''>Advanced Program</b><br />
                                AR and VR projects enhance 3D modeling, UX design, and programming skills, preparing students for future projects.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <p className="text-4xl text-center pt-20">
                        <span className="text-black underline decoration-[#565EFF] px-1">Potential</span>
                        <b className="text-[#565EFF] font-bold px-1">Scope.</b>
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-10 xl:px-0 sm:px-36 max-sm:px-5'>
                <FadeInSectionDownToUp>
                    <p className='text-xl text-center font-bold'>
                        {/* The AR & VR Tech project */}
                        “With <span className='text-[#565EFF]'>AR and VR</span>, the possibilities are endless; we just need the imagination to explore them.”
                    </p>
                    {/* <p className='text-xl text-center'>
                        offers a wide range of potential uses in numerous industries in the future like,
                    </p> */}
                </FadeInSectionDownToUp>
            </div>
            <div className='py-10'>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='py-2 pl-48 xl:pl-48 sm:pl-36 max-sm:pl-5 text-xl xl:text-xl max-sm:text-lg font-bold text-[#565EFF]'>
                            Crafting Your Ideas
                        </p>
                        <p className='py-2 px-48 xl:px-48 sm:px-36 max-sm:px-5 text-xl xl:text-xl max-sm:text-lg text-justify'>
                            Creative ideas come to life as students design VR games or AR apps using tools like Unity and Tinkercad. They turn their imaginations into interactive projects to showcase.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='py-2 pl-48 xl:pl-48 sm:pl-36 max-sm:pl-5 text-xl xl:text-xl max-sm:text-lg font-bold text-[#E355FF]'>
                            Explore Your Interests
                        </p>
                        <p className='py-2 px-48 xl:px-48 sm:px-36 max-sm:px-5 text-xl xl:text-xl max-sm:text-lg text-justify'>
                            Explore careers in game design, virtual worlds, and digital media through hands-on projects that ignite passion and innovation.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='py-2 pl-48 xl:pl-48 sm:pl-36 max-sm:pl-5 text-xl xl:text-xl max-sm:text-lg font-bold text-[#565EFF]'>
                            Share Your Creations
                        </p>
                        <p className='py-2 px-48 xl:px-48 sm:px-36 max-sm:px-5 text-xl xl:text-xl max-sm:text-lg text-justify'>
                            Presenting projects, including VR simulations or AR apps, allows for valuable feedback and inspiration from peers while celebrating creativity and innovation.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
        </div>
    )
}
