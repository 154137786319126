import React from 'react'
import FadeInSectionDownToUp from '../../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function Robotics() {
    return (
        <div>
            <div>
                <img src='https://ik.imagekit.io/mhcockpit1/MHB/Projects/Robotics%202.jpg' alt='Robotics' />
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <h1 className='text-center font-bold text-[#565EFF] text-4xl py-10'>Robotics</h1>
                </FadeInSectionDownToUp>
            </div>
            <div className='space-y-5 pb-10'>
                <FadeInSectionDownToUp>
                    <p className='px-40 xl:px-40 sm:px-36 max-sm:px-5 text-xl max-sm:text-lg text-center'>
                        Build and program your own robot, exploring sensors, mechanics, and coding. Experience the excitement of creating a machine that interacts with the world around it.
                    </p>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <p className='px-48 xl:px-48 sm:px-40 max-sm:px-5 text-xl max-sm:text-lg text-center'>
                        The Robotics project involves designing, assembling, and programming robots. Participants learn about robotics systems, sensors, and movement control as they build and code their robots to perform tasks. Using various components like motors, sensors, and microcontrollers, they gain hands-on experience in controlling the robot's movements and behaviors. This project fosters skills in engineering, problem-solving, and programming.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <p className='text-center text-4xl space-x-2'>
                        <span className=''>Student's</span>
                        <span className='font-bold text-[#E355FF]'>Benefits</span>
                    </p>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <p className='text-center text-xl'>
                        from our projects
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex flex-wrap justify-center mt-10'>
                <div className='relative w-72 h-72 m-3'>
                    <div className='absolute inset-0 bg-cover bg-center' style={{ backgroundImage: "url('https://ik.imagekit.io/mhcockpit1/MHB/Projects/Sticky%20Notes.png')" }} />
                    <div className='relative p-5 flex items-center justify-center h-full'>
                        <FadeInSectionDownToUp>
                            <p className='text-white text-start px-5 -skew-y-6'>
                                <b>Beginner Program</b><br />
                                Learn key concepts in mechanics, electronics, and programming by designing and building simple robots to apply STEM knowledge in real-world scenarios.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
                <div className='relative w-72 h-72 m-3'>
                    <div className='absolute inset-0 bg-cover bg-center' style={{ backgroundImage: "url('https://ik.imagekit.io/mhcockpit1/MHB/Projects/Sticky%20Notes%20Pink.png')" }} />
                    <div className='relative p-5 flex items-center justify-center h-full'>
                        <FadeInSectionDownToUp>
                            <p className='text-white text-start px-5 -skew-y-6'>
                                <b>Intermediate Program</b><br />
                                Explore creative projects that involve constructing robots and developing autonomous systems, encouraging problem-solving and innovative thinking.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
                <div className='relative w-72 h-72 m-3'>
                    <div className='absolute inset-0 bg-cover bg-center' style={{ backgroundImage: "url('https://ik.imagekit.io/mhcockpit1/MHB/Projects/Sticky%20Notes.png')" }} />
                    <div className='relative p-5 flex items-center justify-center h-full'>
                        <FadeInSectionDownToUp>
                            <p className='text-white text-start px-5 -skew-y-6'>
                                <b>Advanced Program</b><br />
                                Gain experience with control systems, sensors, and AI, building the skills necessary for future careers in robotics, engineering, and automation.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <p className="text-4xl text-center pt-20">
                        <span className="text-black underline decoration-[#565EFF] px-1">Potential</span>
                        <b className="text-[#565EFF] font-bold px-1">Scope.</b>
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-10 xl:px-0 sm:px-36 max-sm:px-5'>
                <FadeInSectionDownToUp>
                    <p className='text-xl text-center font-bold'>
                        {/* The Robotics project */}
                        “<span className='text-[#565EFF]'>Robotics</span> is the intersection of imagination and engineering, where dreams take form.”
                    </p>
                    {/* <p className='text-xl text-center'>
                        has a wide future scope and great potential in many different fields.
                    </p> */}
                </FadeInSectionDownToUp>
            </div>
            <div className='py-10'>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='py-2 pl-48 xl:pl-48 sm:pl-36 max-sm:pl-5 text-xl xl:text-xl max-sm:text-lg font-bold text-[#565EFF]'>
                            Crafting Your Ideas
                        </p>
                        <p className='py-2 px-48 xl:px-48 sm:px-36 max-sm:px-5 text-xl xl:text-xl max-sm:text-lg text-justify'>
                            In this project, students engage in designing and constructing their own robotic systems. They explore various components and programming techniques, allowing their creativity to shine as they build functional robots.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='py-2 pl-48 xl:pl-48 sm:pl-36 max-sm:pl-5 text-xl xl:text-xl max-sm:text-lg font-bold text-[#E355FF]'>
                            Explore Your Interests
                        </p>
                        <p className='py-2 px-48 xl:px-48 sm:px-36 max-sm:px-5 text-xl xl:text-xl max-sm:text-lg text-justify'>
                            Students discover different aspects of robotics, from automation to AI. This exploration encourages them to experiment and find their niche in this exciting field while learning valuable technical skills along the way.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='py-2 pl-48 xl:pl-48 sm:pl-36 max-sm:pl-5 text-xl xl:text-xl max-sm:text-lg font-bold text-[#565EFF]'>
                            Share Your Creations
                        </p>
                        <p className='py-2 px-48 xl:px-48 sm:px-36 max-sm:px-5 text-xl xl:text-xl max-sm:text-lg text-justify'>
                            Showcasing their robotic projects provides students with the chance to gather feedback and inspire one another. This collaborative environment celebrates innovation and encourages continuous learning.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
        </div>
    )
}
