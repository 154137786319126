import React from 'react'
import FadeInSectionDownToUp from '../../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function Glider() {
    return (
        <div>
            <div>
                <img src='https://ik.imagekit.io/mhcockpit1/MHB/Projects/Glider%202.jpg' alt='Glider' />
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <h1 className='text-center font-bold text-[#565EFF] text-4xl py-10'>Glider</h1>
                </FadeInSectionDownToUp>
            </div>
            <div className='space-y-5 pb-10'>
                <FadeInSectionDownToUp>
                    <p className='px-40 xl:px-40 sm:px-36 max-sm:px-5 text-xl max-sm:text-lg text-center'>
                        Students will design and construct their own gliders, learning about the principles of flight, stability, and control to create models that soar gracefully through the air.
                    </p>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <p className='px-48 xl:px-48 sm:px-40 max-sm:px-5 text-xl max-sm:text-lg text-center'>
                        Using materials like balsa wood, foam, and lightweight fabrics, students will build their gliders, focusing on essential design elements such as wing shape, weight distribution, and balance. Throughout the project, they will experiment with different designs, testing their gliders in various conditions to understand how changes affect performance. By the end, students will have developed critical skills in engineering, problem-solving, and teamwork, while experiencing the joy of watching their gliders take flight.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <p className='text-center text-4xl space-x-2'>
                        <span className=''>Student's</span>
                        <span className='font-bold text-[#E355FF]'>Benefits</span>
                    </p>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <p className='text-center text-xl'>
                        from our projects
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex flex-wrap justify-center mt-10'>
                <div className='relative w-72 h-72 m-3'>
                    <div className='absolute inset-0 bg-cover bg-center' style={{ backgroundImage: "url('https://ik.imagekit.io/mhcockpit1/MHB/Projects/Sticky%20Notes.png')" }} />
                    <div className='relative p-5 flex items-center justify-center h-full'>
                        <FadeInSectionDownToUp>
                            <p className='text-white text-start px-5 -skew-y-6'>
                                <b>Beginner Program</b><br />
                                Understand the principles of unpowered flight by building a simple glider, focusing on aerodynamics, lift, and balance to achieve stable glides.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
                <div className='relative w-72 h-72 m-3'>
                    <div className='absolute inset-0 bg-cover bg-center' style={{ backgroundImage: "url('https://ik.imagekit.io/mhcockpit1/MHB/Projects/Sticky%20Notes%20Pink.png')" }} />
                    <div className='relative p-5 flex items-center justify-center h-full'>
                        <FadeInSectionDownToUp>
                            <p className='text-white text-start px-5 -skew-y-6'>
                                <b>Intermediate Program</b><br />
                                Design more efficient gliders by experimenting with different wing shapes and materials, optimizing flight duration and stability under various conditions.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
                <div className='relative w-72 h-72 m-3'>
                    <div className='absolute inset-0 bg-cover bg-center' style={{ backgroundImage: "url('https://ik.imagekit.io/mhcockpit1/MHB/Projects/Sticky%20Notes.png')" }} />
                    <div className='relative p-5 flex items-center justify-center h-full'>
                        <FadeInSectionDownToUp>
                            <p className='text-white text-start px-5 -skew-y-6'>
                                <b>Advanced Program</b><br />
                                Create advanced gliders with precise control surfaces and enhanced aerodynamic designs, aiming for longer flight times and improved maneuverability in different wind conditions.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <p className="text-4xl text-center pt-20">
                        <span className="text-black underline decoration-[#565EFF] px-1">Potential</span>
                        <b className="text-[#565EFF] font-bold px-1">Scope.</b>
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-10 xl:px-0 sm:px-36 max-sm:px-5'>
                <FadeInSectionDownToUp>
                    <p className='text-xl text-center font-bold'>
                        {/* The Glider project */}
                        “<span className='text-[#565EFF]'>Gliders</span> demonstrate the beauty of flight without engines, relying on nature’s forces.”
                    </p>
                    {/* <p className='text-xl text-center'>
                        The Aeromodelling Glider project has a wide future scope and great potential in a number of areas,
                    </p> */}
                </FadeInSectionDownToUp>
            </div>
            <div className='py-10'>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='py-2 pl-48 xl:pl-48 sm:pl-36 max-sm:pl-5 text-xl xl:text-xl max-sm:text-lg font-bold text-[#565EFF]'>
                            Crafting Your Ideas
                        </p>
                        <p className='py-2 px-48 xl:px-48 sm:px-36 max-sm:px-5 text-xl xl:text-xl max-sm:text-lg text-justify'>
                            In this project, students design and build gliders, focusing on the principles of lift and drag. They explore aerodynamics, allowing their creativity to shine through their unique designs.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='py-2 pl-48 xl:pl-48 sm:pl-36 max-sm:pl-5 text-xl xl:text-xl max-sm:text-lg font-bold text-[#E355FF]'>
                            Explore Your Interests
                        </p>
                        <p className='py-2 px-48 xl:px-48 sm:px-36 max-sm:px-5 text-xl xl:text-xl max-sm:text-lg text-justify'>
                            Students experiment with different glider designs to achieve optimal flight performance. This exploration encourages them to refine their techniques and understand the science behind gliding.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='py-2 pl-48 xl:pl-48 sm:pl-36 max-sm:pl-5 text-xl xl:text-xl max-sm:text-lg font-bold text-[#565EFF]'>
                            Share Your Creations
                        </p>
                        <p className='py-2 px-48 xl:px-48 sm:px-36 max-sm:px-5 text-xl xl:text-xl max-sm:text-lg text-justify'>
                            By showcasing their glider projects, students can share their experiences and gather feedback from peers. This collaborative environment promotes innovation and continuous improvement.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
        </div>
    )
}
