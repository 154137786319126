import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function Try() {

    const creatingFuture = [

        {
            src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder/robot.png",
            titleLine1: "Learn to Build",
            titleLine2: "your own Robot",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder/AI.png",
            titleLine1: "Discover the",
            titleLine2: "magic of AI",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder/ML.png",
            titleLine1: "Decode with",
            titleLine2: "Machine Learning",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder/explore%20world.png",
            titleLine1: "Explore the wonders",
            titleLine2: "of flight with us",
        },

    ]

    return (
        <div>
            <div className='relative z-50'>
                <img src='https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/cover%20page.jpg' alt='Winter Workshop' />
            </div>
            {/* <div className='relative overflow-hidden'>
                <div className='bg-gradient-to-r from-[#6581ff] via-[#ff65fa] to-[#65ffad] w-full h-full rounded-[100%] absolute z-0 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] blur-[250px] max-sm:blur-[150px] overflow-auto'></div>
                <div className='bg-white relative bg-opacity-50 max-sm:bg-opacity-0 mx-40 max-sm:mx-0 my-20 max-sm:my-0 pt-5 rounded-3xl max-sm:rounded-none shadow-inner max-sm:shadow-transparent'>
                    <FadeInSectionDownToUp>
                        <p className='text-center text-6xl max-sm:text-2xl leading-[80px] from-[#6581ff] via-[#ff65fa] to-[#65ffad] bg-gradient-to-br bg-clip-text text-transparent font-extralight'>Creating</p>
                    </FadeInSectionDownToUp>
                    <FadeInSectionDownToUp>
                        <p className='text-center text-6xl max-sm:text-2xl font-bold leading-[80px] from-[#6581ff] via-[#ff65fa] to-[#03f7fb] bg-gradient-to-r bg-clip-text text-transparent'>
                            Architects of the Future <span className='font-extralight'>in</span> <span className='text-8xl max-sm:text-4xl'>14</span> <span className='font-normal'>Days.</span>
                        </p>
                    </FadeInSectionDownToUp>
                    <div className='flex justify-center items-center pt-0'>
                        <div className='grid grid-cols-4 max-sm:grid-cols-2 gap-20 max-sm:gap-10 p-5 w-full'>
                            {creatingFuture.map((icon, index) => (
                                <div key={index}>
                                    <FadeInSectionDownToUp>
                                        <div className='bg-white bg-opacity-40 backdrop-blur-[150px] rounded-3xl p-5'>
                                            <div className='flex justify-center items-center'>
                                                <img className='w-20 max-sm:w-24' src={icon.src} alt='Winter Workshop' />
                                            </div>
                                            <div className='flex justify-center items-center pt-5'>
                                                <div>
                                                    <p className='text-black text-start text-xl max-sm:text-sm font-bold'>{icon.titleLine1}</p>
                                                    <p className='text-black text-start text-xl max-sm:text-sm font-bold'>{icon.titleLine2}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </FadeInSectionDownToUp>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='relative overflow-hidden'>
                <div className='bg-gradient-to-r from-[#6581ff] via-[#ff65fa] to-[#65ffad] w-full h-full rounded-[100%] absolute z-0 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] blur-[250px] overflow-auto'></div>
                <div className='bg-white relative bg-opacity-50 mx-40 max-sm:mx-0 my-20 max-sm:my-0 pt-5 rounded-3xl max-sm:rounded-none shadow-inner max-sm:shadow-transparent'>
                    <FadeInSectionDownToUp>
                        <p className='text-center text-6xl max-sm:text-2xl leading-[80px] from-[#6581ff] via-[#ff65fa] to-[#65ffad] bg-gradient-to-br bg-clip-text text-transparent font-extralight'>Creating</p>
                    </FadeInSectionDownToUp>
                    <FadeInSectionDownToUp>
                        <p className='text-center text-6xl max-sm:text-2xl font-bold leading-[80px] from-[#6581ff] via-[#ff65fa] to-[#03f7fb] bg-gradient-to-r bg-clip-text text-transparent'>
                            Architects of the Future <span className='font-extralight'>in</span> <span className='text-8xl max-sm:text-4xl'>14</span> <span className='font-normal'>Days.</span>
                        </p>
                    </FadeInSectionDownToUp>
                    <div className='flex justify-center items-center pt-10'>
                        <div className='grid grid-cols-4 max-sm:grid-cols-2 gap-20 max-sm:gap-10 bg-white max-sm:bg-transparent bg-opacity-30 backdrop-blur-[150px] max-sm:backdrop-blur-0 rounded-b-3xl p-5 w-full'>
                            {creatingFuture.map((icon, index) => (
                                <div key={index}>
                                    <FadeInSectionDownToUp>
                                        <div className='bg-white bg-opacity-40 backdrop-blur-[150px] rounded-3xl p-5'>
                                            <div className='flex justify-center items-center'>
                                                <img className='w-20 max-sm:w-24' src={icon.src} alt='Winter Workshop' />
                                            </div>
                                            <div className='flex justify-center items-center pt-5'>
                                                <div>
                                                    <p className='text-black text-start text-xl max-sm:text-sm font-bold'>{icon.titleLine1}</p>
                                                    <p className='text-black text-start text-xl max-sm:text-sm font-bold'>{icon.titleLine2}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </FadeInSectionDownToUp>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='relative overflow-hidden'>
                <div className='bg-gradient-to-r from-[#6581ff] via-[#ff65fa] to-[#65ffad] w-[1000px] max-sm:w-full h-[400px] max-sm:h-full rounded-[100%] absolute z-0 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] blur-[100px] overflow-auto'></div>
                <div className='bg-white relative bg-opacity-50 mx-40 max-sm:mx-0 my-20 max-sm:my-0 pt-5 rounded-3xl max-sm:rounded-none'>
                    <FadeInSectionDownToUp>
                        <p className='text-center text-6xl max-sm:text-2xl leading-[80px] from-[#6581ff] via-[#ff65fa] to-[#65ffad] bg-gradient-to-br bg-clip-text text-transparent font-extralight'>Creating</p>
                    </FadeInSectionDownToUp>
                    <FadeInSectionDownToUp>
                        <p className='text-center text-6xl max-sm:text-2xl font-bold leading-[80px] from-[#6581ff] via-[#ff65fa] to-[#03f7fb] bg-gradient-to-r bg-clip-text text-transparent'>
                            Architects of the Future <span className='font-extralight'>in</span> <span className='text-8xl max-sm:text-4xl'>14</span> <span className='font-normal'>Days.</span>
                        </p>
                    </FadeInSectionDownToUp>
                    <div className='flex justify-center items-center pt-10'>
                        <div className='grid grid-cols-4 max-sm:grid-cols-2 gap-20 max-sm:gap-10 bg-white max-sm:bg-transparent bg-opacity-30 backdrop-blur-[150px] max-sm:backdrop-blur-0 rounded-b-3xl p-5 w-full'>
                            {creatingFuture.map((icon, index) => (
                                <div key={index}>
                                    <FadeInSectionDownToUp>
                                        <div className='bg-white bg-opacity-40 backdrop-blur-[150px] rounded-3xl p-5'>
                                            <div className='flex justify-center items-center'>
                                                <img className='w-20 max-sm:w-24' src={icon.src} alt='Winter Workshop' />
                                            </div>
                                            <div className='flex justify-center items-center pt-5'>
                                                <div>
                                                    <p className='text-black text-start text-xl max-sm:text-sm font-bold'>{icon.titleLine1}</p>
                                                    <p className='text-black text-start text-xl max-sm:text-sm font-bold'>{icon.titleLine2}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </FadeInSectionDownToUp>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='relative overflow-hidden'>
                <div className='bg-gradient-to-r from-[#6581ff] via-[#ff65fa] to-[#65ffad] w-[1000px] h-[400px] rounded-[100%] absolute z-0 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] blur-[100px] overflow-auto'></div>
                <div className='bg-white relative bg-opacity-30 py-20'>
                    <FadeInSectionDownToUp>
                        <p className='text-center text-6xl max-sm:text-2xl leading-[80px] from-[#6581ff] via-[#ff65fa] to-[#65ffad] bg-gradient-to-br bg-clip-text text-transparent font-extralight'>Creating</p>
                    </FadeInSectionDownToUp>
                    <FadeInSectionDownToUp>
                        <p className='text-center text-6xl max-sm:text-2xl font-bold leading-[80px] from-[#6581ff] via-[#ff65fa] to-[#03f7fb] bg-gradient-to-r bg-clip-text text-transparent'>
                            Architects of the Future <span className='font-extralight'>in</span> <span className='text-8xl max-sm:text-4xl'>14</span> <span className='font-normal'>Days.</span>
                        </p>
                    </FadeInSectionDownToUp>
                    <div className='flex justify-center items-center pt-10'>
                        <div className='grid grid-cols-4 max-sm:grid-cols-2 gap-20 max-sm:gap-10'>
                            {creatingFuture.map((icon, index) => (
                                <div key={index}>
                                    <FadeInSectionDownToUp>
                                        <div className='bg-white bg-opacity-30 backdrop-blur-[150px] rounded-3xl p-5'>
                                            <div className='flex justify-center items-center'>
                                                <img className='w-36 max-sm:w-24' src={icon.src} alt='Winter Workshop' />
                                            </div>
                                            <div className='flex justify-center items-center pt-5'>
                                                <div>
                                                    <p className='text-black text-start text-2xl max-sm:text-lg font-bold'>{icon.titleLine1}</p>
                                                    <p className='text-black text-start text-2xl max-sm:text-lg font-bold'>{icon.titleLine2}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </FadeInSectionDownToUp>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}
